import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "../../interfaces/commonInterfaces";
import storage from "../../utils/storage";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    authData: storage.get("__SFA__")
      ? JSON.parse(storage.get("__SFA__"))
      : undefined,
  },
  reducers: {
    login: (state: { authData: IUser }, action: { payload: IUser }) => {
      state.authData = action.payload;
    },
    logout: (state: { authData: undefined }) => {
      state.authData = undefined;
      storage.clear();
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
