import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { DataObject, ICommonList } from "../../../interfaces/commonInterfaces";
import CommonTable from "../commonTable/CommonTable";

export interface IProps {
  tab?:boolean
  data: ICommonList[];
  hasMore: boolean;
  loadMore: () => void;
  from: string;
  loading: boolean;
  setLoading:(val:boolean)=>void;
  fetchData: (firstLoad?: boolean, optionalParams?: DataObject) => void;
}

const CommonHandleInfiniteScroll: React.FC<IProps> = ({
  hasMore,
  from,
  loading,
  fetchData,
  data,
  loadMore,
  tab,
  setLoading
}) => {
  return (
    <div className="theme-table">
      <div className="table-responsive" id="scrollableDiv">
        <InfiniteScroll
          dataLength={data.length}
          next={loadMore}
          hasMore={hasMore}
          loader={loading && (
              // <div className="position-absolute bottom-0 end-50">
              //   <div className="spinner-border" />
              // </div>
              <>  </>
            
            )
          }
          scrollableTarget="scrollableDiv"
        >
          <CommonTable
            data={data}
            from={from}
            loading={loading}
            fetchAgain={fetchData}
            tab={tab}
            setLoading={setLoading}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default CommonHandleInfiniteScroll;
