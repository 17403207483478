import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import moment from "moment";

import {
  DataObject,
  IReportedReviewData,
  IUpdateImageList,
  IUserDetailsCommonPopup,
  IUserDetailsData,
} from "../../../interfaces/commonInterfaces";
import {
  getUserDetailsService,
  updateImageListService,
} from "../../../service/user";
import {
  toastMesageError,
  toastMesageSuccess,
} from "../commonTostMessage/CommonTostMessage";
import CommonSvgFiles from "../commonSvgFiles/CommonSvgFiles";
import {
  ADVERTISEMENT_LIST,
  CREATED_TS,
  REPORTED_REVIEW_LIST,
  USER_LIST,
  USER_TYPES,
} from "../../../constant/commonConstant";
import "./commonPopup.scss";

export interface ICommonModalProps {
  isOpen?: boolean;
  headerMessage: string;
  handleClose?: () => void;
  fetchAgain?: (firstLoad?: boolean, optionalParams?: DataObject) => void;
  imageListData?: {
    id: number;
    index: number;
    url: string;
  };
  reportedReviewData?: IReportedReviewData;
  userDetails?: IUserDetailsCommonPopup;
  from: string;
}

const CommonPopup = ({
  fetchAgain,
  isOpen,
  from,
  imageListData,
  headerMessage,
  reportedReviewData,
  handleClose,
  userDetails,
}: ICommonModalProps) => {
  const { t: translation } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [userDetailData, setUserDetailData] = useState<IUserDetailsData>();
  const [imageList, setImageList] = useState<string[]>([]);

  const getUsersDetails = async (userId: number) => {
    try {
      setLoading(true);
      const response = await getUserDetailsService(userId);
      if (response && response.data && response.data.success) {
        setUserDetailData(response?.data?.data);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (imageListData && imageListData.url) {
      setImageList(JSON.parse(imageListData?.url).map((it: string) => it));
    }
    if (userDetails?.id) getUsersDetails(userDetails.id);
  }, [imageListData]);

  const updateImageList = async (index: number) => {
    try {
      setLoading(true);
      if (imageListData && imageListData.id && index) {
        imageList.splice(index, 1);
        const updatedData: IUpdateImageList = {
          id: imageListData.id,
          url: JSON.stringify(imageList),
          from,
        };
        const response = await updateImageListService(updatedData);
        if (response && response.data && response.data.success) {
          if (fetchAgain) fetchAgain(true);
          setImageList([...imageList]);
          toastMesageSuccess(translation(response?.data?.message));
        } else {
          toastMesageError(translation(response?.data?.message));
        }
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const showDetailsHandler = (key: string, value: string | undefined) => {
    if (key && value) {
      return (
        <div className="model-content-custom">
          <p>
            <b>{translation(key)} : </b>
          </p>
          <p>{value}</p>
        </div>
      );
    }
  };

  switch (from) {
    case USER_LIST:
      return (
        <div>
          <Modal
            isOpen={isOpen as boolean}
            onRequestClose={handleClose}
            contentLabel="Example Modal"
          >
            <div className="modal-header">
              <div className="heading">
                <h2>{headerMessage}</h2>
              </div>
              <div className="modal-closer">
                <button
                  type="button"
                  className="closer-btn"
                  onClick={handleClose}
                >
                  <CommonSvgFiles svgName="cross" />
                </button>
              </div>
            </div>

            <div className="modal-body ">
              <div className="row">
                <div className="card-mid ">
                  <div className="custom-card-style">
                    <h6>{translation("personal")} </h6>
                    {/* SHOW PERSONAL DETAILS */}
                    {showDetailsHandler("name", userDetails?.full_name)}
                    {showDetailsHandler("email", userDetails?.email)}
                    {showDetailsHandler(
                      "account_type",
                      userDetails?.account_type === USER_TYPES.LANDLORD ? "Landlord" : "Tenant"
                    )}
                  </div>

                  {userDetailData ? (
                    <>
                      {userDetailData.properties.length > 0 && (
                        <div className="custom-card-style">
                          <h6>{translation("properties")}</h6>
                          {userDetailData.properties.map((it) => (
                            <>
                              {showDetailsHandler(
                                "property_type",
                                translation(it?.property_type)
                              )}
                              {showDetailsHandler("address", it?.address)}
                            </>
                          ))}
                        </div>
                      )}

                      {userDetailData.reviewsDetails.length > 0 && (
                        <div className="custom-card-style">
                          {userDetailData.reviewsDetails.map(
                            (it, ind: number) => (
                              <>
                                {it.reviewed_by === null && ind === 0 && (
                                  <h6> {translation("reviews")} </h6>
                                )}
                                <h6 className="my-1">
                                  {it.reviewed_by !== null &&
                                    (it?.reviewed_by === userDetails?.id
                                      ? translation("reviews_given")
                                      : translation("reviews_taken"))}
                                </h6>
                                {showDetailsHandler(
                                  "review",
                                  translation(it?.review_type)
                                )}
                                {showDetailsHandler(
                                  "type",
                                  translation(it?.review_topic)
                                )}
                                {showDetailsHandler("rating", it?.rating)}
                              </>
                            )
                          )}
                        </div>
                      )}
                    </>
                  ) : loading ? (
                    <div className="position-absolute end-50">
                      <div className="spinner-border" />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Modal>
        </div>
      );

    case REPORTED_REVIEW_LIST:
      return (
        <div>
          <Modal
            isOpen={isOpen as boolean}
            // onAfterOpen={afterOpenModal}
            onRequestClose={handleClose}
            contentLabel="Example Modal"
          >
            <div className="modal-header">
              <div className="heading">
                <h2>{headerMessage}</h2>
              </div>
              <div className="modal-closer">
                <button
                  type="button"
                  className="closer-btn"
                  onClick={handleClose}
                >
                  <CommonSvgFiles svgName="cross" />
                </button>
              </div>
            </div>

            <div className="modal-body ">
              <div className="row">
                <div className="card-mid">
                  {showDetailsHandler(
                    "reported_by",
                    reportedReviewData?.reportedBy
                  )}
                  {showDetailsHandler(
                    "reported_to",
                    reportedReviewData?.reportedTo
                  )}
                  {showDetailsHandler(
                    "address",
                    translation(reportedReviewData?.property_type as string)
                  )}
                  {showDetailsHandler("comment", reportedReviewData?.comment)}
                  {showDetailsHandler(
                    "date",
                    moment(reportedReviewData?.date as string).format(
                      CREATED_TS
                    )
                  )}
                </div>
              </div>
            </div>
          </Modal>
        </div>
      );
    case ADVERTISEMENT_LIST:
      return (
        <div>
          <Modal
            isOpen={isOpen as boolean}
            // onAfterOpen={afterOpenModal}
            onRequestClose={handleClose}
            contentLabel="Example Modal"
          >
            <div className="modal-header">
              <div className="heading">
                <h2>{headerMessage}</h2>
              </div>
              <div className="modal-closer">
                <button
                  type="button"
                  className="closer-btn"
                  onClick={handleClose}
                >
                  <CommonSvgFiles svgName="cross" />
                </button>
              </div>
            </div>

            <div className="modal-body ">
              <div className="row">
                {imageList && imageList.length > 0 ? (
                  imageList.map((item: string, index: number) => {
                    return (
                      <div
                        className={`${
                          imageList.length > 1 && "col-md-6"
                        }  image-list-custom-modal`}
                        key={index}
                      >
                        <img src={item} alt="imageList" key={index} />
                        <button
                          type="button"
                          disabled={imageList?.length === 1}
                          key={index}
                          id={index.toString()}
                          onClick={() => updateImageList(index)}
                        >
                          <CommonSvgFiles svgName="cross" />
                        </button>
                      </div>
                    );
                  })
                ) : loading ? (
                  <div className="position-absolute end-50">
                    <div className="spinner-border" />
                  </div>
                ) : (
                  <div className=" data-not-found">
                    {translation("no_data_found")}
                  </div>
                )}
              </div>
            </div>
          </Modal>
        </div>
      );
    default:
      return (
        <div>
          <Modal
            isOpen={isOpen as boolean}
            // onAfterOpen={afterOpenModal}
            onRequestClose={handleClose}
            contentLabel="Example Modal"
          >
            <div className="modal-header">
              <div className="heading">
                <h2>{headerMessage}</h2>
              </div>
              <div className="modal-closer">
                <button
                  type="button"
                  className="closer-btn"
                  onClick={handleClose}
                >
                  <CommonSvgFiles svgName="cross" />
                </button>
              </div>
            </div>

            <div className="modal-body ">
              <div className="row">
                {imageList && imageList.length > 0 ? (
                  imageList.map((item: string, index: number) => {
                    return (
                      <div
                        className={`${
                          imageList.length > 1 && "col-md-6"
                        }  image-list-custom-modal`}
                        key={index}
                      >
                        <img src={item} alt="imageList" key={index} />
                        <button
                          type="button"
                          disabled={imageList?.length === 1}
                          key={index}
                          id={index.toString()}
                          onClick={() => updateImageList(index)}
                        >
                          <CommonSvgFiles svgName="cross" />
                        </button>
                      </div>
                    );
                  })
                ) : loading ? (
                  <div className="position-absolute end-50">
                    <div className="spinner-border" />
                  </div>
                ) : (
                  <div className=" data-not-found">
                    {translation("no_data_found")}
                  </div>
                )}
              </div>
            </div>
          </Modal>
        </div>
      );
  }
};

export default CommonPopup;
