import { debounce } from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  APPROVED_CLAIMED_PROPERTY,
  CLAIMED_PROPERTY_LIST,
  LIMIT,
  PENDING_CLAIMED_PROPERTY,
} from "../../../constant/commonConstant";
import { IFilterData } from "../../../interfaces/commonInterfaces";
import { getClaimedPropertyService } from "../../../service/user";
import CommonHandleInfiniteScroll from "../../utilities/commonHandleInfiniteScroll/CommonHandleInfiniteScroll";
import CommonSearch from "../../utilities/commonSearch/CommonSearch";
import useInfiniteScroll from "../../utilities/useInfiniteScroll/useInfiniteScroll";
import "../reviewManagement/Requests.scss";

const ClaimedProperty: React.FC = () => {
  const { t: translation } = useTranslation();
  const [tab, setTab] = useState(false);
  const [filterData, setfilterData] = useState<IFilterData>({
    searchWord: "",
    filterWord: APPROVED_CLAIMED_PROPERTY,
  });

  const { data, loading, hasMore, loadMore, fetchData, pendingCount,setLoading } =
    useInfiniteScroll({
      apiService: getClaimedPropertyService,
      apiParams: {
        searchWord: filterData.searchWord,
        filterWord: filterData.filterWord,
      },
      limit: LIMIT,
      from: CLAIMED_PROPERTY_LIST,
    });

  const handleSearch = debounce(async (e: ChangeEvent<HTMLInputElement>) => {
    setfilterData((pre) => ({
      ...pre,
      ...{
        searchWord: e.target.value,
      },
    }));
  }, 1000);

  useEffect(() => {
    fetchData(true);
  }, [filterData]);

  return (
    <div className="requests-page">
      <div className="container-fluid">
        <div className="page-inner">
          <div className="theme-card">
            <div className="card-top">
              <div className="card-heading">
                <h3>{translation("property_management")}</h3>
              </div>
              <div className="mt-3 mb-1">
                <CommonSearch onChange={handleSearch} />
              </div>
            </div>
            <div className="card-mid">
              <div className="requests-tab">
                <div className="requests-tab-menu">
                  <ul>
                    <li
                      className={`cursor-pointer ${tab || "active"}`}
                      onClick={() => {
                        setTab(false);
                        setfilterData((pre) => ({
                          ...pre,
                          ...{
                            filterWord: APPROVED_CLAIMED_PROPERTY,
                          },
                        }));
                      }}
                    >
                      {translation("approved_claimed_property")}
                    </li>
                    <li
                      className={`cursor-pointer ${tab && "active"}`}
                      onClick={() => {
                        setTab(true);
                        setfilterData((pre) => ({
                          ...pre,
                          ...{
                            filterWord: PENDING_CLAIMED_PROPERTY,
                          },
                        }));
                      }}
                    >
                      {translation("pending_claimed_property")}
                      <span>{pendingCount ? pendingCount : 0}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <CommonHandleInfiniteScroll
                loadMore={loadMore}
                hasMore={hasMore}
                loading={loading}
                data={data}
                from={CLAIMED_PROPERTY_LIST}
                fetchData={fetchData}
                tab={tab}
                setLoading={setLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ClaimedProperty;
