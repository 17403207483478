import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";

import imageCompression from "browser-image-compression";
// import moment from "moment";
import { RootState } from "../../../toolkit";
import { addNewAdvertisementService, advertisementdetailsService } from "../../../service/user";
import { IAddAdvertisement, IEditImages } from "../../../interfaces/commonInterfaces";
import { addAdvertisementValidationSchema } from "../../../validationSchema/authValidation";
import { imageType, videoFileSize, videoType } from "../../../constant/commonConstant";
import {
  toastMesageError,
  toastMesageSuccess,
} from "../../utilities/commonTostMessage/CommonTostMessage";
import { filePath, uploadFileOnS3 } from "../../../helper/helper";

// import CommonDatePicker from "../../utilities/commonDatePicker/CommonDatePicker";
import CommonTextArea from "../../utilities/commonTextArea/CommonTextArea";
import CommonButton from "../../utilities/commonButton/CommonButton";
import CommonFileUpload from "../../utilities/commonFileUpload/CommonFileUpload";
import ROUTES from "../../../constant/routes";
import CommonSvgFiles from "../../utilities/commonSvgFiles/CommonSvgFiles";
import InputWrapper from "../../utilities/commonInput/InputWrapper";
import "./Effects.scss";


const AddNewAdvertisement: React.FC = () => {
  const navigate = useNavigate();
  const params=useParams(); 
  const { t: translation } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [imageList, setimageList] = useState<File[]>([]);
  const [editImage,setEditImage]=useState<IEditImages[]>([])
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());

  const authData = useSelector((state: RootState) => state.auth.authData);

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(addAdvertisementValidationSchema(translation)),
  });

  const onSubmit = async (data: IAddAdvertisement) => {
    try {
      setLoading(true);
      // data.fromDate = moment(startDate).unix();
      // data.toDate = moment(endDate).unix();

      if(imageList && imageList.length>0 || editImage && editImage.length>0){
        const payload:IAddAdvertisement[] = [];
          
        if(editImage &&editImage.length>0){
          payload.push({
            ...data ,
            url:editImage[0].url,
            fileType:editImage[0].fileType,
            fileName:editImage[0].fileName,
            id:editImage[0].id,
            status:editImage[0].status
          })
        };
        if (imageList && imageList.length > 0) {
          for (const item of imageList) {
            const objectUrl = (await uploadFileOnS3(
              item,
              await filePath(authData.id, item.name)
            )) as string;
            payload.push({
              ...data ,
              url: objectUrl,
              fileType:item.type?.split("/")[0],
              fileName:item.name.replace(".png",""),
              id:Number(params.id)
            });
          }
        }
        const response = await addNewAdvertisementService(payload);
        toast.dismiss();
        if (response && response.data && response.data.success) {
          toastMesageSuccess(response?.data?.message);
          setTimeout(() => {
            navigate(ROUTES.ADVERTISEMENT);
          }, 2000);
        } else {
          toastMesageError(translation(response?.data?.message));
        }
        setLoading(false);
      }

      // if (imageList && imageList.length > 0) {
        // if (moment(endDate).isSameOrAfter(startDate)) {
       
        // }
        // else {
        //   toast.dismiss();
        //   toastMesageError(
        //     translation("To date should be greater or equal to from date")
        //   );
        // }
      // } 
      else {
        setError("fileName", {
          type: "custom",
          message: "File is required",
        });
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  /**
   * Handle function for uploading image.
   * @params - file type event
   */
  const fileHandleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    clearErrors("fileName");
    const file = e.target.files;
    if (file && file.length > 0) {
      setEditImage([])
      const fileArray = Array.from(file);
      fileArray.map(
        async (it) =>
          (await handleError(it)) && setimageList([...imageList, ...file])
      );
    }
  };

  const handleError = async (fileObj: File) => {
    if (fileObj) {
      const fileType = fileObj.type.split("/")[0];
      if (imageList && imageList[0]?.type?.split("/")[0] === videoType) {
        imageList.splice(0, imageList.length); // replace old item to new item
      }
      if (fileType === videoType) {
        imageList.splice(0, imageList.length);
        if (fileObj.size >= videoFileSize) {
          setError("fileName", {
            type: "custom",
            message: translation("maximum_file_size_limit_is_30_mb"),
          });
          return false;
        }
      }
      if (fileType !== imageType && fileType !== videoType) {
        setError("fileName", {
          type: "custom",
          message: translation("you_can_upload_only_images_and_video"),
        });
        return false;
      }
      if (fileType === imageType) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 720,
          useWebWorker: true,
        };
        return (await imageCompression(fileObj as File, options)) as File;
        // return true;
      }
      clearErrors("fileName");
      return true;
    }
  };

  const handleRemove = (i: number) => {
    imageList.splice(i, 1);
    setimageList([...imageList]);
    clearErrors("fileName");
  };

  const gettingDetails=async(id:number)=>{
    try {
      const {data}=  await advertisementdetailsService(id);
      if(data && data.success && data.data){
        reset({
          title:data.data.name,
          advertisementDesc:data.data.description,
        });
        setEditImage([{
          id,
          url:data.data.url,
          fileType:data.data.file_type,
          fileName:data.data.file_name,
          status:data.data.status
        }]);
      };
    } catch (error) {
      console.error(error)
    }
  };

  useEffect(() => {
    if(params && params.id){
      gettingDetails(+params.id);
    };
  }, []);


  return (
    <div className="effects-page">
      <div className="container-fluid">
        <div className="page-inner">
          <div className="theme-card">
            <div className="card-top">
              <div className="card-heading">
                <h3 className="mb-3">{translation("add_new_advertisement")}</h3>
              </div>
            </div>
            <div className="card-mid">
              <form
                onSubmit={handleSubmit((data) =>
                  onSubmit(data as IAddAdvertisement)
                )}
              >
                <div className="row mb-4">
                  <div className="">
                    <div className="form-group">
                      <div className="list-relative">
                        <div className="form-group">
                          {/* <p style={{ color: "#0050b3" }}>
                            You can upload only 280 x 350 dimension images{" "}
                          </p> */}
                          <div className="upload-file-control">
                            {/* <div
                              className={`upload-box mx-3 ${
                                Object.keys(errors).length > 0 && "mt-5 mb-5"
                              } `}
                            > */}
                            <CommonFileUpload
                              type="file"
                              className="form-control"
                              name="fileName"
                              control={control}
                              error={errors.fileName}
                              multiple
                              onChange={(e) => fileHandleChange(e)}
                              accept="image/*,video/*"
                              label={
                                imageList.length > 0
                                  ? ""
                                  : translation("upload_picture")
                              }
                            />
                            {/* </div> */}
                            <div className="name row p-2">
                               {imageList && imageList.length>0 && imageList.map((it, inde: number) => {
                                  const fileType = it.type.split("/")[0];
                                  return (
                                    <div
                                      className="card image-list-custom-modal img-fluid p-0"
                                      style={{ width: 250, height: 200 }}
                                    >
                                      {fileType === videoType ? (
                                        <>
                                          <video
                                            width="320"
                                            height="240"
                                            controls
                                            autoPlay
                                            muted
                                            loop 
                                          >
                                            <track default kind="captions"   />
                                            <source
                                              src={URL.createObjectURL(it)}
                                            />
                                          </video>
                                          <button
                                            type="button"
                                            key={inde}
                                            onClick={() => {
                                              handleRemove(inde);
                                              clearErrors("fileName");
                                            }}
                                          >
                                            <CommonSvgFiles svgName="cross" />
                                          </button>
                                          <div className="card-body">
                                            <p className="card-title text-dark">
                                              {it?.name}
                                            </p>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src={URL.createObjectURL(it)}
                                            className="card-img-top"
                                            alt="..."
                                          />
                                          <button
                                            type="button"
                                            key={inde}
                                            onClick={() => handleRemove(inde)}
                                          >
                                            <CommonSvgFiles svgName="cross" />
                                          </button>
                                          <div className="card-body">
                                            <p className="card-title text-dark">
                                              {it?.name??""}
                                            </p>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  );
                                })
                               }
                               {/* EDIT SECTION  */}
                               {editImage && editImage.length>0 && editImage.map((item,idx)=>{
                                    return   <div
                                      className="card image-list-custom-modal img-fluid p-0"
                                      style={{ width: 250, height: 200 }}
                                    >
                                      {item.fileType === videoType ? (
                                        <>
                                          <video
                                            width="320"
                                            height="240"
                                            controls
                                            autoPlay
                                            muted
                                            loop 
                                          >
                                            <track default kind="captions"   />
                                            <source
                                              src={item?.url}
                                            />
                                          </video>
                                          <button
                                            type="button"
                                            key={idx}
                                            onClick={() => setEditImage([])}
                                          >
                                            <CommonSvgFiles svgName="cross" />
                                          </button>
                                          <div className="card-body">
                                            <p className="card-title text-dark">
                                              {item?.fileName?? ""}
                                            </p>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src={item?.url}
                                            className="card-img-top"
                                            alt="..."
                                          />
                                          <button
                                            type="button"
                                            key={idx}
                                            onClick={() => setEditImage([])}
                                          >
                                            <CommonSvgFiles svgName="cross" />
                                          </button>
                                          <div className="card-body">
                                            <p className="card-title text-dark">
                                              {item?.fileName ?? ""}
                                            </p>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                   })}

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <InputWrapper
                      control={control}
                      label={translation("title")}
                      name="title"
                      type="text"
                      className="form-control"
                      error={errors?.title}                      
                      maxLength={40}
                      placeholder={translation("only_40_char")}
                    />
                    <CommonTextArea
                      label={translation("advertisement_desc")}
                      name="advertisementDesc"
                      type="text"
                      control={control}
                      rows={3}
                      maxLength={200}
                      error={errors?.advertisementDesc}
                      placeHolder={translation("only_200_char")}
                    />
                  </div>
                  {/*
                  <div className="col-md-6">
                    <CommonDatePicker
                      label={translation("from_date")}
                      onChange={setStartDate}
                      value={startDate}
                      minDate={startDate}
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <CommonDatePicker
                      label={translation("to_date")}
                      onChange={setEndDate}
                      value={endDate}
                      minDate={endDate}
                      required
                    />
                  </div> */}
                </div>
                <div>
                  <CommonButton
                    label={translation("submit")}
                    className="primary-btn"
                    loading={loading}
                    disabled={Object.keys(errors).length > 0 || loading}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default AddNewAdvertisement;
