import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import authLogo from "../../../assets/images/logo.png";
import ROUTES from "../../../constant/routes";
import CommonSvgFiles from "../commonSvgFiles/CommonSvgFiles";

import "./Sidebar.scss";

const Sidebar: React.FC = () => {
  const { t: translation } = useTranslation();
  return (
    <div className="sidebar">
      <div className="sidebar-top">
        <img src={authLogo} alt="authLogo" />
      </div>
      <div className="sidebar-mid">
        <NavLink className="menu-link" to={ROUTES.DASHBOARD}>
          {translation("dashboard")}
        </NavLink>
        <NavLink className="menu-link" to={ROUTES.REVIEWS}>
          {translation("reviews")}
        </NavLink>
        <NavLink className="menu-link" to={ROUTES.USERS}>
          {translation("users")}
        </NavLink>
        <NavLink className="menu-link" to={ROUTES.REPORTED_REVIEW}>
          {translation("reported_reviews")}
        </NavLink>
        <NavLink className="menu-link" to={ROUTES.CLAIMED_PROPERTY}>
          {translation("property_management")}
        </NavLink>
        <div className="menu-item">
          <span
            className="menu-link collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#onboard"
          >
            {translation("cms")} <CommonSvgFiles svgName="upDownArrow" />
          </span>
          <div className="child-menu collapse" id="onboard">
            <NavLink className="menu-link" to={ROUTES.CONTENT}>
              {translation("home")}
            </NavLink>
            <NavLink className="menu-link" to={ROUTES.ABOUT_US_CONTENT_LIST}>
              {translation("about_us")}
            </NavLink>
          </div>
        </div>
        <NavLink className="menu-link" to={ROUTES.ADVERTISEMENT}>
          {translation("advertisement_management")}
        </NavLink>

        <NavLink className="menu-link" to={ROUTES.CHANGE_PASSWORD}>
          {translation("change_password")}
        </NavLink>
      </div>
      <div className="sidebar-bottom">
        <h6>2022 © Renters Book. All Rights Reserved</h6>
        <p>
          Made with <span>♥</span> by Biz4Group
        </p>
      </div>
    </div>
  );
};
export default Sidebar;
