import endpoints from "../constant/endpoints";
import {
  IAboutUsContent,
  IAddAdvertisement,
  IAddContent,
  IFilterData,
  IPresignedUrl,
  IUpdateImageList,
  IUpdateStatus,
} from "../interfaces/commonInterfaces";
import ApiResponse from "../resources/entity/IApiResponse";
import { http } from "../utils/http";

/**
 *  User List Service
 */
export const getUserListService = (data: IFilterData): Promise<ApiResponse> => {
  const { offset, filterWord, searchWord } = data;
  return http.get(
    `${endpoints.user.USERS}?filterWord=${filterWord}&searchWord=${searchWord}&offset=${offset}`
  );
};
/**
 *  Reviews List Service
 */
export const getReviewsListService = (
  data: IFilterData
): Promise<ApiResponse> => {
  const { offset, filterWord, searchWord } = data;
  return http.get(
    `${endpoints.user.REVIEWS}?filterWord=${filterWord}&searchWord=${searchWord}&offset=${offset}`
  );
};
/**
 *  User Details Service
 */
export const getUserDetailsService = (userId: number): Promise<ApiResponse> => {
  return http.get(`${endpoints.user.USER_DETAILS}?id=${userId}`);
};
/**
 *  Get User Profile
 */
export const getUserProfileService = (userId: number): Promise<ApiResponse> => {
  return http.get(`${endpoints.user.USER_PROFILE}?id=${userId}`);
};
/**
 *  Update User Status Service
 */
export const updateUserStatusService = (
  data: IUpdateStatus
): Promise<ApiResponse> => {
  const { id, status } = data;
  return http.patch(
    `${endpoints.user.UPDATE_USER_STATUS}?id=${id}&status=${status}`
  );
};
/**
 *  Update Claimed Property Status Service
 */
export const updateClaimedPropertyStatusService = (
  data: IUpdateStatus
): Promise<ApiResponse> => {
  const { id, status } = data;
  return http.patch(
    `${endpoints.user.CLAIMED_PROPERTY_STATUS}?id=${id}&status=${status}`
  );
};

/**
 *  Reported Review List Service
 */

export const getReportedReviewService = (
  data: IFilterData
): Promise<ApiResponse> => {
  const { searchWord, offset } = data;
  return http.get(
    `${endpoints.user.REPORTED_REVIEWS}?searchWord=${searchWord}&offset=${offset}`
  );
};

/**
 *  Claimed Property List Service
 */

export const getClaimedPropertyService = (
  data: IFilterData
): Promise<ApiResponse> => {
  const { offset, filterWord, searchWord } = data;
  return http.get(
    `${endpoints.user.CLAIMED_PROPERTY}?filterWord=${filterWord}&searchWord=${searchWord}&offset=${offset}`
  );
};

/**
 *  Content List Service
 */

export const getContentListService = (
  data: IFilterData
): Promise<ApiResponse> => {
  const { offset } = data;
  return http.get(`${endpoints.user.CONTENT_LIST}?offset=${offset}`);
};
/**
 *  Advertisement List Service
 */

export const getAdvertisementListService = (
  data: IFilterData
): Promise<ApiResponse> => {
  const { offset } = data;
  return http.get(`${endpoints.user.ADVERTISEMENT_LIST}?offset=${offset}`);
};

/**
 *  About Us List  Service
 */

export const getAboutUsListService = (
  data: IFilterData
): Promise<ApiResponse> => {
  const { offset } = data;
  return http.get(`${endpoints.user.ABOUT_US_LIST}?offset=${offset}`);
};

/**
 *  Add New Content  Service
 */

export const addNewContentService = (data: IAddContent[]): Promise<ApiResponse> =>
  http.post(`${endpoints.user.ADD_NEW_CONTENT}`, data);

/**
 *  Add New Advertisement Service
 */
export const addNewAdvertisementService = (
  data: IAddAdvertisement[]
): Promise<ApiResponse> => {
  return http.post(`${endpoints.user.ADD_NEW_ADVERTISEMENT}`, data);
};

/**
 *  Add About Us COntent Service
 */

export const addAboutUsContentService = (
  data: IAboutUsContent
): Promise<ApiResponse> =>
  http.post(`${endpoints.user.ADD_ABOUT_US_CONTENT}`, data);
/**
 * Delete Content Service
 */
export const deleteContentService = (id: number): Promise<ApiResponse> => {
  return http.delete(`${endpoints.user.DELETE_CONTENT}/?id=${id}`);
};
/**
 * Delete Advertisement Service
 */
export const deleteAdvertisementService = (
  id: number
): Promise<ApiResponse> => {
  return http.delete(`${endpoints.user.DELETE_ADVERTISEMENT}/?id=${id}`);
};

/**
 * Delete About Us Service
 */

export const deleteAboutUsService = (id: number): Promise<ApiResponse> => {
  return http.delete(`${endpoints.user.DELETE_ABOUT_US}/?id=${id}`);
};

/**
 * Update image list
 */
export const updateImageListService = (
  data: IUpdateImageList
): Promise<ApiResponse> => {
  return http.patch(`${endpoints.user.UPDATE_IMAGE_LIST}`, data);
};

/**
 * Update Content Status Service
 */
export const updateContentStatusService = (
  data: IUpdateStatus
): Promise<ApiResponse> => {
  const { id, status } = data;
  return http.patch(
    `${endpoints.user.UPDATE_CONTENT_STATUS}?id=${id}&status=${status}`
  );
};

/**
 * Update Advertisement Status Service
 */
export const updateAdvertisementStatusService = (
  data: IUpdateStatus
): Promise<ApiResponse> => {
  const { id, status } = data;
  return http.patch(
    `${endpoints.user.UPDATE_ADVERTISEMENT_STATUS}?id=${id}&status=${status}`
  );
};
/**
 * Update Review Rating Status Service
 */
export const updateReviewRatingStatusService = (
  data: IUpdateStatus
): Promise<ApiResponse> => {
  const { id, status } = data;
  return http.patch(
    `${endpoints.user.UPDATE_REVIEW_RATING_STATUS}?id=${id}&status=${status}`
  );
};
/**
 *  Update About Us  Service
 */
export const updateAboutUsStatusService = (
  data: IUpdateStatus
): Promise<ApiResponse> => {
  const { id, status } = data;
  return http.patch(
    `${endpoints.user.UPDATE_ABOUTUS_STATUS}?id=${id}&status=${status}`
  );
};
/**
 * ADVERTISEMENT DETAILS
 * @param id 
 * @returns 
 */
export const advertisementdetailsService = ( id:number): Promise<ApiResponse> => {
  return http.get(
    `${endpoints.user.ADVERTISEMENT_DETAILS}`.replace(":id",id.toString())
  );
};

/**
 * CONTENT DETAILS
 * @param id 
 * @returns 
 */
export const homeContentDetailsService = ( id: number): Promise<ApiResponse> => {
  return http.get(
    `${endpoints.user.CONTENT_DETAILS}`.replace(":id",id.toString())
  );
};


/**
 *  Presigned Url Service
 */

export const getSignedUrl = (data: IPresignedUrl): Promise<ApiResponse> => {
  return http.post(`${endpoints.user.GET_PRESIGNED_URL}`, data);
};
