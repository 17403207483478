import { FC, FormEvent, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ROUTES from "../../../constant/routes";
import { IOtpProps } from "../../../interfaces/authInterface";
import { verifyOtpService } from "../../../service/auth";
import CommonButton from "../../utilities/commonButton/CommonButton";
import authLogo from "../../../assets/images/logo.png";
import authBg from "../../../assets/images/auth-bg.png";
import {
  toastMesageError,
  toastMesageSuccess,
} from "../../utilities/commonTostMessage/CommonTostMessage";

const Verification: FC = () => {
  const { t: translation } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [enterOtp, setEnterOtp] = useState<string>("");
  const [otpError, setOtpError] = useState<string>("");

  const navigate = useNavigate();
  const location = useLocation();
  const otpInfo = location.state as IOtpProps;

  const OnSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!enterOtp || enterOtp.length < 6) {
        setOtpError(translation("otp_error"));
        return;
      }
      setLoading(true);

      if (otpInfo.email && otpInfo.encOtp) {
        const response = await verifyOtpService({
          email: otpInfo.email,
          encOtp: otpInfo.encOtp,
          otp: enterOtp,
        });
        toast.dismiss();
        if (response && response?.data?.success) {
          toastMesageSuccess(translation(response?.data?.message));
          setTimeout(() => {
            navigate(ROUTES.RESET_PASSWORD, {
              state: {
                email: otpInfo?.email,
              },
            });
          }, 2000);
        } else {
          toastMesageError(translation(response?.data?.message));
        }
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <div
      className="userauth-page"
      style={{ backgroundImage: `url(${authBg})` }}
    >
      <div className="auth-header">
        <div className="container-fluid">
          <div className="auth-logo">
            <img src={authLogo} alt="authLogo" />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="page-inner">
          <div className="auth-box">
            <div className="auth-heading">
              <h1>{translation("otp_verfication")}</h1>
              <p>{translation("please_enter_otp_here")}</p>
            </div>
            <form
              onSubmit={(e) => {
                OnSubmit(e);
              }}
            >
              <div className="otp-inputs-list">
                <OtpInput
                  className="otp-input"
                  isInputNum
                  value={enterOtp}
                  onChange={(otp: SetStateAction<string>) => {
                    setOtpError("");
                    setEnterOtp(otp);
                  }}
                  numInputs={6}
                />
              </div>
              <div className="alert-msg error text-center">
                <p>{otpError}</p>
              </div>
              <div className="action-btn mt-4">
                <CommonButton
                  label="SUBMIT"
                  type="submit"
                  className="primary-btn w-100"
                  loading={loading}
                  disabled={loading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Verification;
