import { FC } from "react";
import Page404 from "../../../assets/images/page-not-found.gif";

const PageNotFound: FC = () => {
  return (
    <div className="pagenotfound">
      <img src={Page404} alt="" />
    </div>
  );
};

export default PageNotFound;
