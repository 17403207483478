import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { IFilterData } from "../../../interfaces/commonInterfaces";
import { LIMIT, REPORTED_REVIEW_LIST } from "../../../constant/commonConstant";
import { getReportedReviewService } from "../../../service/user";

import CommonSearch from "../../utilities/commonSearch/CommonSearch";
import useInfiniteScroll from "../../utilities/useInfiniteScroll/useInfiniteScroll";
import CommonHandleInfiniteScroll from "../../utilities/commonHandleInfiniteScroll/CommonHandleInfiniteScroll";

const ReportedReviews: React.FC = () => {
  const { t: translation } = useTranslation();
  const [filterData, setfilterData] = useState<IFilterData>({
    searchWord: "",
    filterWord: "",
  });

  const { data, loading, hasMore, loadMore, fetchData,setLoading } = useInfiniteScroll({
    apiService: getReportedReviewService,
    apiParams: {
      filterWord: filterData.filterWord,
      searchWord: filterData.searchWord,
    },
    limit: LIMIT,
    from: REPORTED_REVIEW_LIST,
  });

  const handleSearch = debounce(async (e: ChangeEvent<HTMLInputElement>) => {
    setfilterData((pre) => ({
      ...pre,
      ...{
        searchWord: e.target.value,
      },
    }));
  }, 1000);

  useEffect(() => {
    fetchData(true);
  }, [filterData]);

  return (
    <div className="users-page">
      <div className="container-fluid">
        <div className="page-inner">
          <div className="theme-card">
            <div className="card-top">
              <div className="card-heading">
                <h3>{translation("reported_reviews")}</h3>
              </div>
            </div>
            <div className="card-mid">
              <div className="mb-3">
                <CommonSearch onChange={handleSearch} />
              </div>
              <CommonHandleInfiniteScroll
                loadMore={loadMore}
                hasMore={hasMore}
                loading={loading}
                data={data}
                from={REPORTED_REVIEW_LIST}
                fetchData={fetchData}
                setLoading={setLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReportedReviews;
