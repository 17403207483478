import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { isExpired } from "react-jwt";
import { toastMesageError } from "../components/utilities/commonTostMessage/CommonTostMessage";
import { RootState } from "../toolkit";
import { logout } from "../toolkit/slices/auth";
import ROUTES from "../constant/routes";

/**
 * Private routes are only visible for login users
 * */
interface Props {
  component: React.ComponentType;
}
const ProtectedRoutes: React.FC<Props> = ({ component: RouteComponent }) => {
  const { t: translation } = useTranslation();
  const dispatch = useDispatch();
  const tokenData: string = useSelector(
    (state: RootState) => state?.auth?.authData?.token
  );
  const sessionExpire = () => {
    if (tokenData) {
      if (isExpired(tokenData)) {
        toastMesageError(translation("token-expired"));
        dispatch(logout());
        return <Navigate to={ROUTES.LOGIN} />;
      }
    }
  };
  useEffect(() => {
    sessionExpire();
  }, []);
  if (tokenData) return <RouteComponent />;
  return <Navigate to={ROUTES.LOGIN} />;
};

export default ProtectedRoutes;
