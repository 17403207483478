import moment from "moment";

export const ALPHANUMERIC_REGEX = /^[a-z\d\-_\s]+$/i;
export const ALPHABATE_WITH_SPACE_REGEX = /^[a-zA-Z ]*$/;
export const PHONE_REGEX = /\b\d{10}\b/g;
export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/;
export const NAME_REGEX = /^[a-z ,.'-]+$/i;
// export const NAME_REGEX = /^[ a-zA-Z0-9\-’]+$/;
export const NUMBER_REGEX = /^[0-9]*$/;
export const NUMBER_REGEX_WITH_SPACE = /^[0-9 ]*$/;
export const NEW_LINE_REGEX = /\r?\n/g;
export const ALPHABATE_REGEX = /\b[A-Za-z]/g;
export const CITY_REGEX = /^(?=.*[a-zA-Z ])( )([a-zA-Z0-9]+)$/;
export const DATE_FORMAT = "MM-DD-YYYY";
export const TIME_FORMAT = "hh:mm A";
export const ADDRESS_REGEX = /^[a-zA-Z0-9\s,._'"/\\-]*$/;
export const currentTimeStamp = Number(moment(new Date().getTime()));

export const ACCOUNT_TYPE = [
  { label: "Filter", value: "" },
  { label: "Landlord", value: "0" },
  { label: "Tenant", value: "1" },
  // { label: "Both", value: "2" },
];

export const LIVING_STATUS = [
  { label: "Former ", value: "former" },
  { label: "Current", value: "current" },
];

export const PROPERTY_TYPE = [
  // { key: "", value: "Select Property Type" },
  { key: "house", value: "House" },
  { key: "apartment", value: "Apartment" },
  { key: "condo", value: "Condo" },
  { key: "commercial_property", value: "Commercial Property" },
];

export const Review = [
  { key: "excellent", value: "Excellent" },
  { key: "very_good", value: "Very good" },
  { key: "good", value: "Good" },
  { key: "fair", value: "Fair" },
  { key: "poor", value: "Poor" },
];

export const PROPERTY_RATING = [
  { key: "security_and_safety", value: "Security & Safety" },
  { key: "cleanliness", value: "Cleanliness" },
  { key: "lighting", value: "Lighting" },
  { key: "heating_and_cooling", value: "Heating & Cooling" },
  { key: "snow_removal", value: "Snow removal" },
  { key: "pets", value: "Pests" },
  {
    key: "quality_of_amenities",
    value: "Quality of amenities? (Laundry room, gym, etc.)",
  },
];

export const Slug = [
  { key: "select", value: "" },
  { key: "address", value: "address" },
  { key: "landlord", value: "landlord" },
  { key: "tenant", value: "Tenant" },
];

export const LANDLORD_RATING = [
  { key: "management", value: "Management" },
  { key: "maintenance_staff", value: "Maintenance staff" },
  { key: "timely_repairs", value: "Timely repairs" },
  { key: "rent", value: "Rent" },
  {
    key: "recomend_to_other",
    value: "Would you recommend to a family or friend?",
  },
  { key: "increasing_rent", value: " Frequent or excessive rent increase" },
  {
    key: "incepection_of_property",
    value: "Conducted frequent or scheduled inspections of property",
  },
  { key: "value_for_money", value: " Value for money" },
  { key: "live_again", value: "Would you live here again?" },
];

export const TENANT_RATING = [
  { key: "paid_rent_on_time", value: "Pays / Paid rent on time" },
  { key: "noise", value: "Excessive noise" },
  { key: "cleanliness", value: "Cleanliness" },
  {
    key: "followed_rules_and_regulations",
    value: "Followed rules and regulations",
  },
  {
    key: "issues_with_children",
    value: "Issues with children",
  },
  { key: "issue_with_pets", value: "Issues with pets" },
  {
    key: "utility_use",
    value: "Utility use",
  },
  {
    key: "would_you_rent_to_this_tenant_again",
    value: "Would you rent to this tenant again?",
  },
  {
    key: "problems_with_tenant_exiting_unit",
    value: "Problems with tenant exiting unit",
  },
  {
    key: "destruction_to_property",
    value: "Destruction to property",
  },
  {
    key: "frequent_or_permanent_guests",
    value: "Frequent or permanent guests",
  },
  {
    key: "will_you_give_a_favorable_reference",
    value: "Will you give a favorable reference",
  },
];
export const FILTERBY = [
  { key: "address", value: "Address" },
  { key: "landlord", value: "Landlord" },
  { key: "tenant", value: "Tenant" },
];

export const LIMIT = 10;

export const ReportedReviewTd = [
  "Date",
  "Reported By",
  "Reported To",
  "Address",
  "Property",
  "Comment",
  "Details",
];
export const AboutUsListTd = ["S.No", "Text", "Status", "Action", "Delete"];
export const UserListTd = [
  "Name",
  "Email",
  "Type",
  "Profile",
  "Status",
  "Action",
];
export const contentListTd = [
  "S.No",
  "Icon",
  "Content Type",
  // "Date Range",
  // "Content Text",
  "Status",
  "Action",
  "Edit",
  "Delete",
];
export const advertisementListTd = [
  "S.No",
  "Icon",
  // "Date Range",
  // "Content Text",
  "Status",
  "Action",
  "Edit",
  "Delete",
];
export const ClaimedPropertyListTd = [
  "Date",
  "Current Landlord",
  "Claimed By",
  "Address",
  "Type",
  "Comment",
  "Status",
  "Action",
];

export const ReviewListTd = [
  "Date",
  "Review By",
  "Address",
  "Property Type",
  "Rate Landlord/Tenant",
  "Rating",
  "Review",
  "Status",
  "Action",
];

export const videoFileSize = 30000000;

export const landlordType = "0";
export const TenantType = "1";

export const videoType = "video";
export const imageType = "image";

// export const NO_CUSTOM_REVIEW = "0";
export const PENDING_REVIEW = "1";
export const APPROVED_REVIEW = "2";
export const REJECTED_REVIEW = "3";
export const All_REVIEW = "4";

export const APPROVED_CLAIMED_PROPERTY = "2";
export const PENDING_CLAIMED_PROPERTY = "1";

export const USER_LIST = "userList";
export const CONTENT_LIST = "contentList";
export const ADVERTISEMENT_LIST = "advertisementList";
export const ABOUT_US_LIST = "aboutUsList";
export const REVIEW_LIST = "reviewList";
export const CLAIMED_PROPERTY_LIST = "claimedPropertyList";
export const REPORTED_REVIEW_LIST = "reportedReviewList";
export const USER_PROFILE = "userProfile";
export const DATE_FORMATE = "MMM Do";
export const CREATED_TS = "MMM Do YY";
export const ImageHeight = 350;
export const ImageWidth = 280;

export const CLAIMED_PROPERTY_STATUS = {
  REQUESTED: "0",
  REQUEST_PENDING: "1",
  APPROVED: "2",
};

export const ACTIVE_INACTIVE_STATUS={
  ACTIVE: "1",
  INACTIVE: "0",
}


export const USER_TYPES = {
  LANDLORD: "0",
  TENANT: "1",
  BOTH: "2",
};


export const CUSTOM_REVIEW_STATUS = {
  NO_REVIEW: "0",
  HAVE_REVIEW: "1",
  APPROVED_REVIEW: "2",
  REJECTED_REVIEW:"3",
};

export const CUSTOM_REVIEW_TEXT = {
  PEDNING:"Pending",
  APPROVED:"Approved",
  REJECTED:"Rejected"
}
