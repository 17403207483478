import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { loginValidationSchema } from "../../../validationSchema/authValidation";
import { ILoginData } from "../../../interfaces/authInterface";
import { loginService } from "../../../service/auth";
import { login } from "../../../toolkit/slices/auth";

import {
  toastMesageError,
  toastMesageSuccess,
} from "../../utilities/commonTostMessage/CommonTostMessage";

import Storage from "../../../utils/storage";
import CommonButton from "../../utilities/commonButton/CommonButton";
import InputWrapper from "../../utilities/commonInput/InputWrapper";
import authLogo from "../../../assets/images/logo.png";
import authBg from "../../../assets/images/auth-bg.png";
import ROUTES from "../../../constant/routes";
import VisibleIcon from "../../../assets/images/visibility.png";
import "./UserAuth.scss";

const Login: React.FC = () => {
  const { t: translation } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(loginValidationSchema(translation)),
  });

  const onSubmit = async (data: ILoginData) => {
    try {
      setLoading(true);
      const response = await loginService(data);
      toast.dismiss();
      if (response && response?.data && response?.data?.success) {
        dispatch(login(response?.data?.data));

        Storage.set("__ATK__", response?.data?.data?.token);
        Storage.set("__SFA__", JSON.stringify(response?.data?.data));
        toastMesageSuccess(translation(response?.data?.message));
        navigate(ROUTES.DASHBOARD, { state: response?.data });
      } else {
        toastMesageError(translation(response?.data?.message));
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <div
      className="userauth-page"
      style={{ backgroundImage: `url(${authBg})` }}
    >
      <div className="auth-header">
        <div className="container-fluid">
          <div className="auth-logo">
            <img src={authLogo} alt="authLogo" />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="page-inner">
          <div className="auth-box">
            <div className="auth-heading">
              <h1>{translation("welcome_back")}</h1>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>
            <form
              onSubmit={handleSubmit((data) => onSubmit(data as ILoginData))}
            >
              <InputWrapper
                control={control}
                label={translation("email")}
                name="email"
                type="email"
                className="form-control"
                error={errors?.email}
              />

              <InputWrapper
                control={control}
                label={translation("password")}
                name="password"
                type="password"
                className="form-control"
                iconSrc={VisibleIcon}
                icon
                error={errors?.password}
              />

              <div className="auth-inline-content">
                {/* <label className="custom-checkbox mb-0">
                  Remember Me
                  <input type="checkbox" />
                  <span className="checkmark"></span>
                </label> */}
                <div className="forgot-link">
                  <Link to={ROUTES.FORGOT_PASSWORD}>Forgot Password?</Link>
                </div>
              </div>
              <div className="action-btn mt-4">
                <CommonButton
                  label="LOGIN"
                  type="submit"
                  className="primary-btn w-100"
                  loading={loading}
                  disabled={loading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default Login;
