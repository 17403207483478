import { ButtonHTMLAttributes, FC } from "react";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  label?: string;
  iconSrc?: string;
  loading?: boolean;
  type?: "button" | "submit";
}

const CommonButton: FC<Props> = ({
  type,
  className,
  label,
  iconSrc,
  disabled,
  loading,
  onClick,
  ...props
}) => {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`theme-button ${className}`}
      disabled={disabled}
      {...props}
      onClick={onClick}
    >
      {label}
      {iconSrc ? <img src={iconSrc} alt="icon" className="icon-btn" /> : null}
      {loading && <div className="spinner-border" />}
    </button>
  );
};

export default CommonButton;
