const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  DASHBOARD: "/dashboard/:id",
  USERS: "/users",
  ADMIN: "/admin",
  REVIEWS: "/review",
  CHANGE_PASSWORD: "/change-password",
  USER_DETAIL: "/user-details",
  REPORTED_REVIEW: "/reported-reviews",
  CLAIMED_PROPERTY: "/claimed-property",
  CONTENT: "/content",
  ADVERTISEMENT: "/advertisement",
  ABOUT_US_CONTENT_LIST: "/about-us-list",
  ADD_ADVERTISEMENT: "/add-advertisement/:id",
  ADD_ABOUT_US_CONTENT: "/add-about-us-content",
  OTP_VERIFICATION: "/verification",
  RESET_PASSWORD: "/reset-password",
  PAGE_NOT_FOUND: "*",
};
export default ROUTES;
