import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { toast, ToastContainer } from "react-toastify";
import { updatePasswordValidatinonSchema } from "../../../validationSchema/userValidation";
import { changePasswordService } from "../../../service/auth";
import InputWrapper from "../../utilities/commonInput/InputWrapper";
import CommonButton from "../../utilities/commonButton/CommonButton";
import eyeIcon from "../../../assets/images/eye-icon.png";
import {
  toastMesageError,
  toastMesageSuccess,
} from "../../utilities/commonTostMessage/CommonTostMessage";
import { IUpdatePasssword } from "../../../interfaces/commonInterfaces";
import { RootState } from "../../../toolkit";

const ChangePassword: React.FC = () => {
  const { t: translation } = useTranslation();
  const [loading, setLoading] = useState(false);

  const authData = useSelector((state: RootState) => state.auth.authData);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(updatePasswordValidatinonSchema(translation)),
  });

  const onSubmitUpdatePassword = async (
    data: IUpdatePasssword,
    e: React.BaseSyntheticEvent<object, any, any> | undefined
  ) => {
    try {
      setLoading(true);
      if (authData.token) {
        const response = await changePasswordService({
          email: authData.email as string,
          oldPassword: data.oldPassword,
          newPassword: data.newPassword,
        });
        toast.dismiss();
        reset({ oldPassword: "", newPassword: "", confirmNewPassword: "" });

        if (response && response?.data && response?.data?.success) {
          toastMesageSuccess(translation(response?.data?.message));
          e?.target.reset();
        } else {
          toastMesageError(translation(response?.data?.message));
        }
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <div className="change-password-page">
      <div className="container-fluid">
        <div className="page-inner">
          <div className="theme-card">
            <div className="card-top">
              <div className="card-heading">
                <h3>{translation("change_password")}</h3>
              </div>
            </div>
            <div className="card-mid">
              <form
                onSubmit={handleSubmit((data, e) =>
                  onSubmitUpdatePassword(data as IUpdatePasssword, e)
                )}
              >
                <div className="row">
                  <div className="col-md-6">
                    <InputWrapper
                      label="Old Password"
                      name="oldPassword"
                      type="password"
                      control={control}
                      error={errors.oldPassword}
                      icon
                      iconSrc={eyeIcon}
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-6">
                    <InputWrapper
                      label="New Password"
                      name="newPassword"
                      type="password"
                      control={control}
                      icon
                      iconSrc={eyeIcon}
                      className="left"
                      error={errors.newPassword}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputWrapper
                      label="Confirm Password"
                      name="confirmNewPassword"
                      type="password"
                      control={control}
                      icon
                      iconSrc={eyeIcon}
                      className="left"
                      error={errors.confirmNewPassword}
                    />
                  </div>
                </div>
                <div>
                  <CommonButton
                    label="Submit"
                    className="primary-btn"
                    loading={loading}
                    disabled={loading}
                    type="submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default ChangePassword;
