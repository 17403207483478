import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";

import InputWrapper from "../../utilities/commonInput/InputWrapper";
import authLogo from "../../../assets/images/logo.png";
import authBg from "../../../assets/images/auth-bg.png";
import authBack from "../../../assets/images/auth-back.png";
import eyeIcon from "../../../assets/images/eye-icon.png";
import { resetPasswordValidatinonSchema } from "../../../validationSchema/userValidation";

import "./UserAuth.scss";
import CommonButton from "../../utilities/commonButton/CommonButton";
import { IUpdatePasssword } from "../../../interfaces/commonInterfaces";
import {
  toastMesageError,
  toastMesageSuccess,
} from "../../utilities/commonTostMessage/CommonTostMessage";
import { resetPasswordService } from "../../../service/auth";
import ROUTES from "../../../constant/routes";

const SetNewPassword: React.FC = () => {
  const { t: translation } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(resetPasswordValidatinonSchema(translation)),
  });

  const onSubmitUpdatePassword = async (
    data: IUpdatePasssword,
    e: React.BaseSyntheticEvent<object, any, any> | undefined
  ) => {
    try {
      setLoading(true);
      if (location.state.email) {
        data.email = location.state.email;
        const response = await resetPasswordService(data);
        toast.dismiss();
        if (response && response?.data && response?.data?.success) {
          toastMesageSuccess(translation(response?.data?.message));
          e?.target.reset();
          setTimeout(() => {
            navigate(ROUTES.LOGIN);
          }, 2000);
        } else {
          toastMesageError(translation(response?.data?.message));
        }
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <div
      className="userauth-page"
      style={{ backgroundImage: `url(${authBg})` }}
    >
      <div className="auth-header">
        <div className="container-fluid">
          <div className="auth-logo">
            <img src={authLogo} alt="authLogo" />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="page-inner">
          <div className="auth-box">
            <div className="auth-top">
              <Link to="/login">
                <img src={authBack} alt="authBack" />
                {translation("back")}
              </Link>
            </div>
            <div className="auth-heading">
              <h1> {translation("set_new_password")}</h1>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>
            <form
              onSubmit={handleSubmit((data, e) =>
                onSubmitUpdatePassword(data as IUpdatePasssword, e)
              )}
            >
              <InputWrapper
                label="New Password"
                name="newPassword"
                type="password"
                control={control}
                icon
                iconSrc={eyeIcon}
                className="left"
                error={errors.newPassword}
              />
              <InputWrapper
                label="Confirm Password"
                name="confirmNewPassword"
                type="password"
                control={control}
                icon
                iconSrc={eyeIcon}
                className="left"
                error={errors.confirmNewPassword}
              />

              <div className="action-btn">
                <CommonButton
                  label="SUBMIT"
                  className="primary-btn w-100"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default SetNewPassword;
