import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import SunEditor from "suneditor-react";
import {
  toastMesageError,
  toastMesageSuccess,
} from "../../utilities/commonTostMessage/CommonTostMessage";
import { IAboutUsContent } from "../../../interfaces/commonInterfaces";
import { addAboutUsContentService } from "../../../service/user";
import "suneditor/dist/css/suneditor.min.css";
import ROUTES from "../../../constant/routes";
import CommonButton from "../../utilities/commonButton/CommonButton";

const AddAboutUsContent: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation().state as { id: number; about_us_text: string };
  const { t: translation } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [aboutUsText, setAboutUSText] = useState("");

  const { handleSubmit } = useForm();
  const onSubmit = async (data: IAboutUsContent) => {
    try {
      if (aboutUsText && aboutUsText.length > 0) {
        setLoading(true);
        data.aboutUsText = aboutUsText;
        if (location && location.id) {
          data.id = location.id;
        }
        const response = await addAboutUsContentService(data);
        toast.dismiss();
        if (response && response.data && response.data.success) {
          toastMesageSuccess(translation(response?.data?.message));
          setTimeout(() => {
            navigate(ROUTES.ABOUT_US_CONTENT_LIST);
          }, 2000);
        } else {
          toastMesageError(translation(response?.data?.message));
        }
        setLoading(false);
      } else {
        toastMesageError("Please fill content");
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleChangeEditor = (text: string) => {
    setAboutUSText(text);
  };

  return (
    <div className="effects-page">
      <div className="container-fluid">
        <div className="page-inner">
          <div className="theme-card">
            <div className="card-top">
              <div className="card-heading">
                <h3>{translation("add_new_about_us_content")}</h3>
              </div>
            </div>
            <div className="card-mid">
              <form
                onSubmit={handleSubmit((data) =>
                  onSubmit(data as IAboutUsContent)
                )}
              >
                <div className="row mb-4">
                  <div className="col-md-6">
                    <div className="form-group">
                      {/* <CommonTextArea
                        label={translation("about_us_text")}
                        name="aboutUsText"
                        type="text"
                        control={control}
                        rows={12}
                        error={errors?.aboutUsText}
                      /> */}

                      <SunEditor
                        defaultValue={
                          location &&
                          location.about_us_text &&
                          location.about_us_text
                            ? location.about_us_text
                            : aboutUsText
                        }
                        onChange={handleChangeEditor}
                        placeholder={translation("please_type_here")}
                        width="1000px"
                        height="400px"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <CommonButton
                    label={translation("submit")}
                    className="primary-btn"
                    loading={loading}
                    disabled={loading}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddAboutUsContent;
