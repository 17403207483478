import { FC, InputHTMLAttributes, useState } from "react";
import { Control, FieldError, FieldErrors, FieldValues } from "react-hook-form";
import CommonInput from "./CommonInput";
import VisibleIcon from "../../../assets/images/visibility.png";
import HideIcon from "../../../assets/images/hide.png";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: boolean;
  align?: string;
  type?:
    | "email"
    | "hidden"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "url"
    | "file";
  parentClassName?: string;
  iconSrc?: string;
  control: Control;
  value?: string;
  error?: FieldError | FieldErrors<FieldValues>;
  onIconClick?: () => void;
  label?: string;
}

const InputWrapper: FC<Props> = ({
  align,
  className,
  icon,
  parentClassName,
  error,
  onIconClick,
  label,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={`form-group ${parentClassName}`}>
      {label ? <label htmlFor="inputWrapper">{label}</label> : null}
      {icon ? (
        <div className={`icon-align ${align === "left" ? "left" : "right"}`}>
          {showPassword ? (
            <span onClick={() => setShowPassword(!showPassword)}>
              <img
                src={VisibleIcon}
                alt="icon"
                onClick={onIconClick}
                className={props.type === "password" ? "cursorA" : ""}
              />
            </span>
          ) : (
            <span onClick={() => setShowPassword(!showPassword)}>
              <img
                src={HideIcon}
                alt="icon"
                onClick={onIconClick}
                className={props.type === "password" ? "cursorA" : ""}
              />
            </span>
          )}

          <CommonInput
            className={`form-control ${className}`}
            {...props}
            type={showPassword ? "text" : "password"}
          />
        </div>
      ) : (
        <CommonInput className={`form-control ${className}`} {...props} />
      )}
      {error && error.message && (
        <p className="alert-msg error">{error?.message as string}</p>
      )}
    </div>
  );
};

export default InputWrapper;
