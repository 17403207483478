import * as yup from "yup";
import { TFunction } from "react-i18next";

import {
  email,
  password,
  firstName,
  lastName,
  accountType,
  emptyField,
  aboutUsText,
  textLengthValidation,
} from "./commonFieldValidation";

// Validation for SignUp Page fields
const loginValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    email: email(translation),
    password: emptyField(translation, "password"),
  });
const signupValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    firstName: firstName(translation),
    lastName: lastName(translation),
    email: email(translation),
    password: password(translation),
    accountType: accountType(translation),
    // termAndConditionAccepted: termsConditions(translation),
  });
const forgetPasswordValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    email: email(translation),
  });
const restPasswordValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    password: password(translation),
    confirmPassword: yup
      .string()
      .required(translation("confirm_pass_req"))
      .oneOf(
        [yup.ref("password"), null],
        translation("confirm_password_must_match")
      ),
  });

const addAdvertisementValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    // fileName: emptyField(translation, "file"),
    // advertisementDesc: advertisementDesc(translation),
    advertisementDesc: textLengthValidation(translation,200,"only_200_char"),
    title: textLengthValidation(translation,40,"only_40_char"),

    // fromDate: emptyField(translation, "From Date"),
    // toDate: emptyField(translation, "To Date"),
  });

const addAboutUsContentValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    aboutUsText: aboutUsText(translation),
  });

export {
  signupValidationSchema,
  loginValidationSchema,
  forgetPasswordValidationSchema,
  restPasswordValidationSchema,
  addAdvertisementValidationSchema,
  addAboutUsContentValidationSchema,
};
