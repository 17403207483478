import { ChangeEvent, FC, InputHTMLAttributes } from "react";

import { Control, Controller } from "react-hook-form";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  control?: Control;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const CommonInput: FC<IProps> = ({
  control,
  name,
  value,
  onChange,
  ...props
}) => {
  return (
    <Controller
      render={({ field }) => (
        <input
          {...field}
          {...props}
          onChange={(e) => {
            field.onChange(e);
            if (onChange) {
              onChange(e);
            }
          }}
        />
      )}
      name={name as string}
      defaultValue={value || ""}
      control={control}
    />
  );
};

export default CommonInput;
