import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { ABOUT_US_LIST, LIMIT } from "../../../constant/commonConstant";
import ROUTES from "../../../constant/routes";
import { getAboutUsListService } from "../../../service/user";
import CommonButton from "../../utilities/commonButton/CommonButton";
import CommonTable from "../../utilities/commonTable/CommonTable";
import useInfiniteScroll from "../../utilities/useInfiniteScroll/useInfiniteScroll";

const AboutUsContentList: React.FC = () => {
  const navigate = useNavigate();
  const { t: translation } = useTranslation();

  const { data, loading, hasMore, loadMore, fetchData,setLoading } = useInfiniteScroll({
    apiService: getAboutUsListService,
    limit: LIMIT,
    from: ABOUT_US_LIST,
  });

  useEffect(() => {
    fetchData(true);
  }, []);

  const handleInfiniteScroll = () => {
    return (
      <div className="theme-table">
        <div className="table-responsive " id="scrollableDiv">
          <InfiniteScroll
            dataLength={data?.length}
            next={loadMore}
            hasMore={hasMore}
            loader={
              loading && (
                <div className="position-absolute bottom-50 end-50">
                  <div className="spinner-border" />
                </div>
              )
            }
            scrollableTarget="scrollableDiv"
          >
            <CommonTable
              data={data}
              from={ABOUT_US_LIST}
              loading={loading}
              fetchAgain={fetchData}
              setLoading={setLoading}
            />
          </InfiniteScroll>
        </div>
      </div>
    );
  };

  return (
    <div className="effects-page">
      <div className="container-fluid">
        <div className="page-inner">
          <div className="theme-card">
            <div className="card-top">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-4">
                  <div className="card-heading">
                    <h3>{translation("about_us_management")}</h3>
                  </div>
                </div>
                <div className="col-md-12 col-lg-8">
                  <div className="common-head-action">
                    <CommonButton
                      label={translation("add_about_us_content")}
                      className="primary-btn"
                      onClick={() => navigate(ROUTES.ADD_ABOUT_US_CONTENT)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-mid">{handleInfiniteScroll()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsContentList;
