import React from "react";
import PublicRoutes from "./routes/PublicRoute";
import "./assets/theme/App.scss";
import "bootstrap/dist/css/bootstrap.css";
import "./components/i18n/I18n";

const App = () => {
  return (
    <div className="App">
      <PublicRoutes />
    </div>
  );
};

export default App;
