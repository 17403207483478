import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";

import ROUTES from "../../../constant/routes";
import { forgetPasswordValidationSchema } from "../../../validationSchema/authValidation";
import { forgotPasswordService } from "../../../service/auth";
import {
  toastMesageError,
  toastMesageSuccess,
} from "../../utilities/commonTostMessage/CommonTostMessage";

import CommonButton from "../../utilities/commonButton/CommonButton";
import InputWrapper from "../../utilities/commonInput/InputWrapper";
import authLogo from "../../../assets/images/logo.png";
import authBg from "../../../assets/images/auth-bg.png";
import authBack from "../../../assets/images/auth-back.png";
import { IForgetPassword } from "../../../interfaces/authInterface";
import "./UserAuth.scss";

const ForgotPassword: React.FC = () => {
  const { t: translation } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(forgetPasswordValidationSchema(translation)),
  });

  const onSubmit = async (data: IForgetPassword) => {
    try {
      setLoading(true);
      const response = await forgotPasswordService(data);
      toast.dismiss();
      if (response && response?.data?.success) {
        toastMesageSuccess(translation(response?.data?.message));
        if (data.email && response?.data?.data) {
          setTimeout(() => {
            navigate(ROUTES.OTP_VERIFICATION, {
              state: {
                email: data.email,
                encOtp: response?.data?.data,
              },
            });
          }, 2000);
        }
      } else {
        toastMesageError(translation(response?.data?.message));
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };
  return (
    <div
      className="userauth-page"
      style={{ backgroundImage: `url(${authBg})` }}
    >
      <div className="auth-header">
        <div className="container-fluid">
          <div className="auth-logo">
            <img src={authLogo} alt="authLogo" />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="page-inner">
          <div className="auth-box">
            <div className="auth-top">
              <Link to="/login">
                <img src={authBack} alt="authBack" />
                {translation("back")}
              </Link>
            </div>
            <div className="auth-heading">
              <h1>Forgot Password</h1>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>
            <form
              onSubmit={handleSubmit((data) =>
                onSubmit(data as IForgetPassword)
              )}
            >
              <InputWrapper
                label="Email Address"
                name="email"
                type="email"
                control={control}
                error={errors.email}
              />
              <div className="action-btn">
                <CommonButton
                  label="SUBMIT"
                  className="primary-btn w-100"
                  loading={loading}
                  disabled={loading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default ForgotPassword;
