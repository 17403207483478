const ROUTES = {
  auth: {
    LOGIN: "/admin-auth/login",
    FORGOT_PASSWORD: "/admin-auth/forgot-password",
    VERIFY_OTP: "/admin-auth/otp-verification",
    CHANGE_PASSWORD: "/admin-auth/change-password",
    RESET_PASSWORD: "/admin-auth/reset-password",
  },

  user: {
    USERS: "/admin/user-list",
    REVIEWS: "/admin/review-list",
    USER_DETAILS: "admin/user-details",
    USER_PROFILE: "admin/user-profile",
    UPDATE_USER_STATUS: "admin/user-status",
    REPORTED_REVIEWS: "admin/reported-reviews",
    CLAIMED_PROPERTY: "admin/claimed-property-list",
    CLAIMED_PROPERTY_STATUS: "admin/approved-claimed-property",
    CONTENT_LIST: "admin/content-list",
    ADVERTISEMENT_LIST: "admin/advertisement-list",
    ABOUT_US_LIST: "admin/about-us-list",
    ADD_NEW_CONTENT: "admin/add-content",
    ADD_NEW_ADVERTISEMENT: "admin/add-advertisement",
    ADD_ABOUT_US_CONTENT: "admin/add-about-us-content",
    GET_PRESIGNED_URL: "admin/pre-signed-url",
    DELETE_CONTENT: "admin/delete-content",
    DELETE_ADVERTISEMENT: "admin/delete-advertisement",
    DELETE_ABOUT_US: "admin/delete-about-us",
    UPDATE_IMAGE_LIST: "admin/update-image-list",
    UPDATE_CONTENT_STATUS: "admin/content-status",
    UPDATE_ADVERTISEMENT_STATUS: "admin/advertisement-status",
    UPDATE_REVIEW_RATING_STATUS: "admin/review-rating-status",
    UPDATE_ABOUTUS_STATUS: "admin/about-us-status",
    ADVERTISEMENT_DETAILS: "admin/advertisement-details/:id",
    CONTENT_DETAILS: "admin/content-details/:id",
  },
};

export default ROUTES;
