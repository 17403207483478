import React, { ChangeEvent, InputHTMLAttributes } from "react";
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  FieldValues,
} from "react-hook-form";
import CommonErrorText from "../commonErrorText/CommonErrorText";
import CommonSvgFiles from "../commonSvgFiles/CommonSvgFiles";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: boolean;
  align?: string;
  type: "file";
  control: Control;
  disabled?: boolean;
  value?: string;
  className?: string;
  placeholder?: string;
  error?: FieldError | FieldErrors<FieldValues>;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
  label?: string;
  multiple?: boolean;
}

const CommonFileUpload: React.FC<Props> = (props) => {
  const {
    error,
    type,
    name,
    multiple = false,
    control,
    onChange,
    disabled = false,
    value,
    placeholder,
    className,
    accept,
    label,
  } = props;
  return (
    <>
      <div className="upload-box">
        <label htmlFor="fileUpload">{label}</label>
        <Controller
          render={({ field }) => (
            <input
              {...field}
              accept={accept}
              key={Math.random() * 3}
              type={type}
              disabled={disabled}
              className={className}
              placeholder={placeholder}
              value={value}
              multiple={multiple}
              onChange={(e) => {
                field.onChange(e);
                if (onChange) {
                  onChange(e);
                }
              }}
            />
          )}
          name={name}
          control={control}
        />
        <CommonSvgFiles svgName="uploadFile" />
      </div>
      <CommonErrorText errors={error} />
    </>
  );
};

export default CommonFileUpload;
