import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { convert } from "html-to-text";
import { toast, ToastContainer } from "react-toastify";
import {
  AboutUsListTd,
  ABOUT_US_LIST,
  advertisementListTd,
  ADVERTISEMENT_LIST,
  ClaimedPropertyListTd,
  CLAIMED_PROPERTY_LIST,
  contentListTd,
  CONTENT_LIST,
  CREATED_TS,
  imageType,
  ReportedReviewTd,
  REPORTED_REVIEW_LIST,
  ReviewListTd,
  REVIEW_LIST,
  UserListTd,
  USER_LIST,
  ACTIVE_INACTIVE_STATUS,
  CLAIMED_PROPERTY_STATUS,
  CUSTOM_REVIEW_STATUS,
  USER_TYPES,

} from "../../../constant/commonConstant";
import {
  DataObject,
  ICommonList,
  IReportedReviewData,
  IUserDetailsCommonPopup,
} from "../../../interfaces/commonInterfaces";
import {
  deleteAboutUsService,
  deleteContentService,
  updateAboutUsStatusService,
  updateContentStatusService,
  updateClaimedPropertyStatusService,
  updateReviewRatingStatusService,
  updateUserStatusService,
  deleteAdvertisementService,
  updateAdvertisementStatusService,
} from "../../../service/user";
import {
  toastMesageError,
  toastMesageSuccess,
} from "../commonTostMessage/CommonTostMessage";
import { customRviewText, fromHandlerForCommonPopup, handleToggle, headerMsgHandlerForCommonPopup } from "../../../helper/helper";

import playIcon from "../../../assets/images/play-icon.png";
// import noDataFound from "../../../assets/images/no-data-found.gif";
import CommonPopup from "../commonPopup/CommonPopup";
import CommonSvgFiles from "../commonSvgFiles/CommonSvgFiles";
import ROUTES from "../../../constant/routes";

interface IProps {
  data: ICommonList[] | undefined;
  from: string;
  loading?: boolean;
  setLoading:(val:boolean)=>void;
  fetchAgain?: (firstLoad?: boolean, optionalParams?: DataObject) => void;
  tab?: boolean;
}

const CommonTable: React.FC<IProps> = ({
  data,
  from,
  loading,
  fetchAgain,
  tab,
  setLoading
}) => {
  const { t: translation } = useTranslation();
  const navigate = useNavigate();
  const [loadingId, setLoadingId] = useState(0);
  const [openModal, setOpendModal] = useState(false);
  const [reportedReviewDetails, setReportedReviewDetails] =
    useState<IReportedReviewData>();
  const [userDetails, setUserDetails] = useState<IUserDetailsCommonPopup>();

  const deleteAdvertisement = async (id: number) => {
    try {
      setLoading(true);
      let response;
      switch (from) {
        case CONTENT_LIST:
          response = await deleteContentService(id);
          break;
        case ADVERTISEMENT_LIST:
          response = await deleteAdvertisementService(id);
          break;
        case ABOUT_US_LIST:
          response = await deleteAboutUsService(id);
          break;
        default:
          break;
      }
      if (response && response.data && response.data.success) {
        if (fetchAgain) fetchAgain(true);
        toastMesageSuccess(translation(response?.data?.message));
      } else {
        toastMesageError(translation(response?.data?.message));
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const commonStatusUpdate = async (updateData: { id: number; status: string;}) => {
    try {
      setLoading(true);
      toast.dismiss();
      let response;
      switch (from) {
        case CLAIMED_PROPERTY_LIST:
          response = await updateClaimedPropertyStatusService(updateData);
          break;
        case USER_LIST:
          response = await updateUserStatusService(updateData);
          break;
        case CONTENT_LIST:
          response = await updateContentStatusService(updateData);
          break;
        case ADVERTISEMENT_LIST:
          response = await updateAdvertisementStatusService(updateData);
          break;
        case REVIEW_LIST:
          response = await updateReviewRatingStatusService(updateData);
          break;
        case ABOUT_US_LIST:
          response = await updateAboutUsStatusService(updateData);
          break;
        default:
          break;
      }

      if (response && response.data && response.data.success) {
        if (fetchAgain) fetchAgain(true);
        toastMesageSuccess(translation(response?.data?.message));
      } else {
        toastMesageError(translation(response?.data?.message));
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleThData = () => {
    switch (from) {
      case USER_LIST:
        return UserListTd.map((td) => <td>{td}</td>);
      case REVIEW_LIST:
        return ReviewListTd.map((td, idx: number) => (
          <td>{idx === 0 ? "Date" : td}</td>
        ));
      case REPORTED_REVIEW_LIST:
        return ReportedReviewTd.map((td) => <td>{td}</td>);
      case CLAIMED_PROPERTY_LIST:
        return ClaimedPropertyListTd.map((td, idx: number) => (
          <td>{idx === 7 && !tab ? "" : td}</td>
        ));
      case CONTENT_LIST:
        return contentListTd.map((td) => <td>{td}</td>);
      case ADVERTISEMENT_LIST:
        return advertisementListTd.map((td) => <td>{td}</td>);
      case ABOUT_US_LIST:
        return AboutUsListTd.map((td) => <td>{td}</td>);
      default:
        break;
    }
  };
  const handleTrData = (item: ICommonList, index: number) => {
    const {
      id,
      user_to,
      user_by,
      rating,
      custom_review,
      reviewed_by_user,
      custom_review_status,
      review_topic,
      property,
      created_ts,
      comment,
      url,
      status,
      full_name,
      email,
      account_type,
      is_active,
      file_type,
      about_us_text,
    } = item;
    

    switch (from) {
      case USER_LIST:
        return (
          <tr key={index}>
            <td>{full_name ? full_name : ""} </td>
            <td>{email ? email : "-"}</td>
            <td>
              {account_type === USER_TYPES.LANDLORD
                ? translation("landlord")
                : translation("tenant")}
            </td>
            <td>
              <u
                key={index}
                className="fa-solid fa-eye cursor-pointer text-primary"
                id={id.toString()}
                onClick={() => {
                  setOpendModal(true);
                  setUserDetails((pre) => ({
                    ...pre,
                    id,
                    full_name,
                    email,
                    account_type,
                  }));
                }}
              >
                {translation("view_profile")}
              </u>
            </td>
            <td>
              <span
                className={`${
                  is_active === ACTIVE_INACTIVE_STATUS.ACTIVE ? "text-success" : "text-danger"
                }`}
              >
                {is_active === ACTIVE_INACTIVE_STATUS.INACTIVE
                  ? translation("in_active")
                  : translation("active")}
              </span>
            </td>
            <td>
              <div className="switch-taggle-content mb-2 ">
                <div className="switch-taggle">
                  <input
                    className="cursor-pointer"
                    checked={is_active === ACTIVE_INACTIVE_STATUS.INACTIVE ? false : true}
                    type="checkbox"
                    onClick={() => {
                      if (is_active !== undefined) {
                        setLoadingId(index);
                        commonStatusUpdate({id,
                          status: is_active.toString(),});
                      }
                    }}
                  />
                  <span className="switch-slider round" />
                </div>
              </div>
            </td>
          </tr>
        );
      case REVIEW_LIST:
        return (
          <tr key={index}>
            <td style={{ whiteSpace: "nowrap" }}>
              {moment(created_ts).format("MMM Do YY")}
            </td>
            <td>
              {reviewed_by_user?.full_name ? reviewed_by_user?.full_name : "-"}
            </td>
            <td>{property?.address ? property?.address : "-"}</td>
            <td>
              {translation(
                property?.property_type ? property?.property_type : "-"
              )}
            </td>
            <td>{translation(review_topic ? review_topic : "")}</td>
            <td>{rating}</td>
            <td>{custom_review ? custom_review : "-"}</td>
            <td>
              <span
                className={`${
                  custom_review_status === CUSTOM_REVIEW_STATUS.APPROVED_REVIEW
                    ? "text-success"
                    : custom_review_status === CUSTOM_REVIEW_STATUS.REJECTED_REVIEW
                    ? "text-danger"
                    : "text-primary"
                }`}
              >
                {customRviewText(custom_review_status)}
              </span>
            </td>
            <td>
              {custom_review_status === CUSTOM_REVIEW_STATUS.HAVE_REVIEW && (
                <>
                  {/* For Approved Reviews  */}
                  <u
                    className="text-success cursor-pointer"
                    onClick={() => {
                      setLoadingId(index);
                      const updateData = {
                        id,
                        status: CUSTOM_REVIEW_STATUS.APPROVED_REVIEW,
                      };
                      commonStatusUpdate(updateData);
                    }}
                  >
                    {translation("approve")}
                  </u>
                  <br />
                  {/* For Rejected Reviews  */}
                  <u
                    className="text-danger cursor-pointer"
                    onClick={() => {
                      setLoadingId(index);
                      const updateData = {
                        id,
                        status: CUSTOM_REVIEW_STATUS.REJECTED_REVIEW,
                      };
                      commonStatusUpdate(updateData);
                    }}
                  >
                    {translation("reject")}
                  </u>
                </>
              )}
              {/* Show Reject toggle  */}
              {custom_review_status === CUSTOM_REVIEW_STATUS.REJECTED_REVIEW && (
                <div className="switch-taggle-content mb-2 ">
                  <div className="switch-taggle">
                    <input
                      className="cursor-pointer"
                      checked={handleToggle(custom_review_status)}
                      type="checkbox"
                      onClick={() => {
                        if (custom_review_status !== undefined) {
                          setLoadingId(index);
                          const updateData = {
                            id,
                            status: CUSTOM_REVIEW_STATUS.APPROVED_REVIEW,
                          };
                          commonStatusUpdate(updateData);
                        }
                      }}
                    />
                    <span className="switch-slider round" />
                  </div>
                </div>
              )}
              {/* Show Approved toggle  */}
              {custom_review_status === CUSTOM_REVIEW_STATUS.APPROVED_REVIEW && (
                <div className="switch-taggle-content mb-2 ">
                  <div className="switch-taggle">
                    <input
                      className="cursor-pointer"
                      checked={handleToggle(custom_review_status)}
                      type="checkbox"
                      onClick={() => {
                        if (custom_review_status !== undefined) {
                          setLoadingId(index);
                          const updateData = {
                            id,
                            status: CUSTOM_REVIEW_STATUS.REJECTED_REVIEW,
                          };
                          commonStatusUpdate(updateData);
                        }
                      }}
                    />
                    <span className="switch-slider round" />
                  </div>
                </div>
              )}
            </td>
          </tr>
        );
      case REPORTED_REVIEW_LIST:
        return (
          <tr>
            <td style={{ whiteSpace: "nowrap" }}>
              {moment(created_ts).format(CREATED_TS)}
            </td>
            {/* Reported By  */}
            <td>{user_by?.full_name ? user_by?.full_name : "-"} </td>
            {/* Reported To  */}
            <td>{user_to?.full_name ? user_to?.full_name : "-"} </td>
            <td>{property?.address ? property?.address : ""}</td>
            <td>
              {translation(
                property?.property_type ? property?.property_type : ""
              )}
            </td>
            <td style={{ minWidth: "300px" }}>{comment ? comment : "-"}</td>
            <td>
              <u
                key={index}
                className="fa-solid fa-eye cursor-pointer text-primary"
                id={id.toString()}
                onClick={() => {
                  setOpendModal(true);
                  setReportedReviewDetails((pre) => ({
                    ...pre,
                    id,
                    reportedBy: user_by?.full_name,
                    reportedTo: user_to?.full_name,
                    address: property?.address,
                    property_type: property?.property_type,
                    date: created_ts,
                    comment,
                    from: REPORTED_REVIEW_LIST,
                  }));
                }}
              >
                {translation("view_more")}
              </u>
            </td>
          </tr>
        );

      case CLAIMED_PROPERTY_LIST:
        return (
          <tr>
            <td style={{ whiteSpace: "nowrap" }}>
              {moment(created_ts).format(CREATED_TS)}
            </td>
            <td>{user_to?.full_name ? user_to?.full_name : "-"}</td>
            <td>{user_by?.full_name ? user_by?.full_name : "-"}</td>
            <td>{property?.address ? property?.address : "-"}</td>
            <td>
              {property?.property_type
                ? translation(property?.property_type)
                : "-"}
            </td>
            <td>{comment ? comment : "-"} </td>
            <td>
              <span
                className={`${status === CLAIMED_PROPERTY_STATUS.APPROVED ? "text-success" : "text-danger"}`}
              >
                {status === CLAIMED_PROPERTY_STATUS.APPROVED
                  ? translation("approved")
                  : translation("pending")}
              </span>
            </td>
            {tab && (
              <td>
                <div className="switch-taggle-content mb-2 ">
                  <div className="switch-taggle">
                    <input
                      className={` ${
                        status === CLAIMED_PROPERTY_STATUS.REQUEST_PENDING ? "cursor-pointer " : "disabled"
                      } `}
                      disabled={
                        status === CLAIMED_PROPERTY_STATUS.APPROVED || (loadingId === index && loading)
                      }
                      checked={status === CLAIMED_PROPERTY_STATUS.APPROVED ? true : false}
                      type="checkbox"
                      onClick={() => {
                        if (status !== undefined) {
                          setLoadingId(index);
                          commonStatusUpdate({
                            id,
                            status,
                          });
                        }
                      }}
                    />
                    <span className="switch-slider round" />
                  </div>
                </div>
              </td>
            )}
          </tr>
        );

      case CONTENT_LIST:
        return (
          <tr key={index}>
            <td>{index + 1} </td>
            {/* Icon  */}
            <td>
              <div className="cursor-pointer">
                {file_type === imageType ? (
                  <div className="circle-img">
                    <img
                      loading="lazy"
                      key={index}
                      src={url}
                      alt="imgPlaceholder"
                      onClick={() => openInNewTab(url)}
                    />
                  </div>
                ) : (
                  <div className="table-video-circle">
                    <i className="fas fas-play" />
                    <img
                      loading="lazy"
                      key={index}
                      src={playIcon}
                      alt="imgPlaceholder"
                      onClick={() => openInNewTab(url)}
                    />
                  </div>
                )}
              </div>
            </td>
            {/* Content Type  */}
            <td>{translation(file_type) ? translation(file_type) : ""}</td>
            {/* Time Range  */}
            {/* <td>{`${moment.unix(from_date).format(DATE_FORMATE)} ${"-"} ${moment
              .unix(to_date)
              .format(DATE_FORMATE)}`}
            </td> */}
            {/* Content Text  */}
            {/* <td>{name ? name : ""}</td> */}
            {/* Content  status  */}
            <td>
              <span
                className={`${status === ACTIVE_INACTIVE_STATUS.ACTIVE ? "text-success" : "text-danger"}`}
              >
                {status === ACTIVE_INACTIVE_STATUS.INACTIVE
                  ? translation("in_active")
                  : translation("active")}
              </span>
            </td>
            <td key={index}>
              <div className="switch-taggle-content mb-2 ">
                <div className="switch-taggle">
                  <input
                    key={index}
                    id={id.toString()}
                    className="cursor-pointer"
                    checked={status === ACTIVE_INACTIVE_STATUS.INACTIVE ? false : true}
                    type="checkbox"
                    disabled={status === ACTIVE_INACTIVE_STATUS.ACTIVE || (loadingId === index && loading)}
                    onClick={() => {
                      if (status !== undefined) {
                        setLoadingId(index);
                        commonStatusUpdate({
                          id,
                          status,
                        });
                      }
                    }}
                  />
                  <span className="switch-slider round" />
                </div>
              </div>
            </td>

            <td className="d-flex">
              <div className={`inline-action ${status === ACTIVE_INACTIVE_STATUS.ACTIVE && "disabled"}`}>
                <button
                  type="button"
                  key={index}
                  disabled={status === ACTIVE_INACTIVE_STATUS.ACTIVE}
                  className={`icon-btn danger-icon-btn mx-3 ${status === ACTIVE_INACTIVE_STATUS.ACTIVE && "disabled"} `}
                  onClick={() => {
                    navigate(ROUTES.DASHBOARD.replace(":id",id.toString()))
                  }}
                >
                  <CommonSvgFiles svgName="edit" />
                </button>
              </div>
            </td>
            {/* DELETE CONTENT  */}
            <td>
              <div className={`inline-action ${status === ACTIVE_INACTIVE_STATUS.ACTIVE && "disabled"}`}>
                <button
                  type="button"
                  key={index}
                  disabled={status === ACTIVE_INACTIVE_STATUS.ACTIVE}
                  className={`icon-btn mx-3 ${
                    status === ACTIVE_INACTIVE_STATUS.ACTIVE ? "dark-icon-btn" : "danger-icon-btn"
                  }`}
                  onClick={() => {
                    deleteAdvertisement(id);
                  }}
                >
                  <CommonSvgFiles svgName="delete" />
                </button>
              </div>
            </td>
          </tr>
        );
      case ADVERTISEMENT_LIST:
        return (
          <tr key={index}>
            <td>{index + 1} </td>
            {/* Icon  */}
            <td>
              <div className="cursor-pointer">
                {file_type === imageType ? (
                  <div className="circle-img">
                    <img
                      loading="lazy"
                      key={index}
                      src={url}
                      alt="imgPlaceholder"
                      onClick={() => openInNewTab(url)}
                    />
                  </div>
                ) : (
                  <div className="table-video-circle">
                    <i className="fas fas-play" />
                    <img
                      key={index}
                      src={playIcon}
                      alt="imgPlaceholder"
                      onClick={() => openInNewTab(url)}
                    />
                  </div>
                )}
              </div>
            </td>
            {/* Content Type  */}
            {/* Time Range  */}

            {/* <td>
              {`${moment.unix(from_date).format(DATE_FORMATE)} ${"-"} ${moment
                .unix(to_date)
                .format(DATE_FORMATE)}`}
            </td> */}

            {/* Content Text  */}
            {/* <td>{name ? name : ""}</td> */}
            {/* Content  status  */}
            <td>
              <span
                className={`${status === ACTIVE_INACTIVE_STATUS.ACTIVE ? "text-success" : "text-danger"}`}
              >
                {status === ACTIVE_INACTIVE_STATUS.INACTIVE
                  ? translation("in_active")
                  : translation("active")}
              </span>
            </td>
            <td key={index}>
              <div className="switch-taggle-content mb-2 ">
                <div className="switch-taggle">
                  <input
                    key={index}
                    id={id.toString()}
                    className="cursor-pointer"
                    checked={status === ACTIVE_INACTIVE_STATUS.INACTIVE ? false : true}
                    type="checkbox"
                    disabled={loadingId === index && loading}
                    onClick={() => {
                      if (status !== undefined) {
                        setLoadingId(index);
                        commonStatusUpdate({
                          id,
                          status,
                        });

                        // TIME RANGE fUNCTIONLITY

                        // const startDate = moment.unix(from_date);
                        // const endDate = moment.unix(to_date);
                        // if (
                        //   moment(startDate).isSameOrBefore(
                        //     currentTimeStamp,
                        //     "date"
                        //   ) &&
                        //   moment(endDate).isSameOrAfter(
                        //     currentTimeStamp,
                        //     "date"
                        //   )
                        // ) {
                        //   setLoadingId(index);
                        //   commonStatusUpdate({
                        //     id,
                        //     status,
                        //   });
                        // } else {
                        //   toast.dismiss();
                        //   toastMesageError(
                        //     translation(
                        //       "you_can_not_active_expired_and_future_date_advertisement"
                        //     )
                        //   );
                        // }
                      }
                    }}
                  />
                  <span className="switch-slider round" />
                </div>
              </div>
            </td>

            <td className="d-flex">
              {/* Update Image List Popup */}
              <div className={`inline-action ${status === ACTIVE_INACTIVE_STATUS.ACTIVE && "disabled"}`}>
                <button
                  type="button"
                  key={index}
                  disabled={status === ACTIVE_INACTIVE_STATUS.ACTIVE }
                  className={`icon-btn danger-icon-btn mx-3 ${status === ACTIVE_INACTIVE_STATUS.ACTIVE&& "disabled"}`}
                  onClick={() => {
                    navigate(ROUTES.ADD_ADVERTISEMENT.replace(":id",id.toString()))
                  }}
                >
                  <CommonSvgFiles svgName="edit" />
                </button>
              </div>
            </td>
            {/* DELETE CONTENT  */}
            <td>
              <div className={`inline-action ${status === ACTIVE_INACTIVE_STATUS.ACTIVE && "disabled"}`}>
                <button
                  type="button"
                  key={index}
                  disabled={status === ACTIVE_INACTIVE_STATUS.ACTIVE}
                  className={`icon-btn mx-3 ${
                    status === ACTIVE_INACTIVE_STATUS.ACTIVE ? "dark-icon-btn" : "danger-icon-btn"
                  }`}
                  onClick={() => {
                    deleteAdvertisement(id);
                  }}
                >
                  <CommonSvgFiles svgName="delete" />
                </button>
              </div>
            </td>
          </tr>
        );
      case ABOUT_US_LIST:
        return (
          <tr key={index}>
            <td> {index + 1}</td>
            <td>
              {about_us_text
                ? convert(about_us_text).substring(0, 50).concat(" . . .")
                : ""}
            </td>
            <td>
              <span
                className={`${status === ACTIVE_INACTIVE_STATUS.ACTIVE ? "text-success" : "text-danger"}`}
              >
                {status === ACTIVE_INACTIVE_STATUS.INACTIVE
                  ? translation("in_active")
                  : translation("active")}
              </span>
            </td>
            <td>
              <div className="switch-taggle-content mb-2 ">
                <div className="switch-taggle">
                  <input
                    key={index}
                    id={id.toString()}
                    className="cursor-pointer"
                    checked={status === ACTIVE_INACTIVE_STATUS.INACTIVE ? false : true}
                    type="checkbox"
                    disabled={loadingId === index && loading}
                    onClick={() => {
                      if (status !== undefined) {
                        setLoadingId(index);
                        commonStatusUpdate({
                          id,
                          status,
                        });
                      }
                    }}
                  />
                  <span className="switch-slider round" />
                </div>
                <div className={`inline-action `}>
                  <button
                    type="button"
                    key={index}
                    disabled={loading}
                    className="icon-btn danger-icon-btn mx-3"
                    onClick={() => {
                      navigate(ROUTES.ADD_ABOUT_US_CONTENT, {
                        state: { id, about_us_text },
                      });
                    }}
                  >
                    <CommonSvgFiles svgName="edit" />
                  </button>
                </div>
              </div>
            </td>
            <td>
              <div className={`inline-action ${status === ACTIVE_INACTIVE_STATUS.ACTIVE && "disabled"}`}>
                <button
                  type="button"
                  key={index}
                  disabled={status === ACTIVE_INACTIVE_STATUS.ACTIVE}
                  className={`icon-btn mx-3 ${
                    status === ACTIVE_INACTIVE_STATUS.ACTIVE ? "dark-icon-btn" : "danger-icon-btn"
                  }`}
                  onClick={() => {
                    deleteAdvertisement(id);
                  }}
                >
                  <CommonSvgFiles svgName="delete" />
                </button>
              </div>
            </td>
          </tr>
        );
      default:
        break;
    }
  };

  const openInNewTab = (url: string) => {
    window?.open(url, "_blank")?.focus();
  };

  const handelCloseModal = () => {
    setOpendModal(false);
  };

  return (
    <>
      <table className="table">
        <thead>
          <tr>{handleThData()}</tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((item, index: number) => handleTrData(item, index))
          ) : loading  ? (
            <tr>
              <td colSpan={9}>
                <div className="position-absolute end-50">
                  <div className="spinner-border" />
                </div>
              </td>
            </tr>
          ) : (
            <> {translation("no_data_found")}</>
          )}
        </tbody>
      </table>
      <ToastContainer />
      {openModal && (
        <CommonPopup
          fetchAgain={fetchAgain}
          isOpen={openModal}
          reportedReviewData={reportedReviewDetails}
          userDetails={userDetails}
          from={fromHandlerForCommonPopup(from)}
          headerMessage={headerMsgHandlerForCommonPopup(from)}
          handleClose={handelCloseModal}
        />
      )}
    </>
  );
};

export default CommonTable;
