import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { getUserDetailsService } from "../../../service/user";
import { IUserDetails } from "../../../interfaces/commonInterfaces";
import InputWrapper from "../../utilities/commonInput/InputWrapper";
import "./Users.scss";
import { USER_TYPES } from "../../../constant/commonConstant";

const UserDetail: React.FC = () => {
  const { t: translation } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState<IUserDetails>();
  const { control, reset } = useForm();

  const getUsersDetails = async (userId: number) => {
    try {
      setLoading(true);
      const response = await getUserDetailsService(userId);
      if (response && response.data && response.data.success) {
        const data = response?.data?.data[0];
        setUserDetails(data);
        reset({
          userType:
            data?.user_account_type === USER_TYPES.LANDLORD
              ? translation("landlord")
              : translation("tenant"),
          email: data?.user_email,
          fullName: `${data?.user_full_name
            .charAt(0)
            .toUpperCase()}${data?.user_full_name.substring(1)}`,
          propertyType: translation(data?.landlord_property_type),
          fullAddress: data?.landlord_address,
          reviewType: translation(data?.reviewRating_review_type),
          reviewTopic: translation(data?.reviewRating_review_topic),
          rating: data?.reviewRating_rating,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // if (location.state.id) {
    getUsersDetails(181);
    // }
  }, []);

  if (loading) {
    return (
      <div className="position-absolute end-50">
        <div className="spinner-border" />
      </div>
    );
  }

  return (
    <div className="change-password-page">
      <div className="container-fluid">
        <div className="page-inner">
          {/* Personal Details  */}
          <div className="theme-card mb-4">
            <div className="card-top">
              <div className="card-heading">
                <h3> {translation("personal")}</h3>
              </div>
            </div>
            <div className="card-mid">
              <div className="row">
                <div className="col-4">
                  <InputWrapper
                    label="User Type"
                    name="userType"
                    control={control}
                    disabled
                  />
                </div>
                <div className="col-4">
                  <InputWrapper
                    label="Full Name"
                    name="fullName"
                    control={control}
                    disabled
                  />
                </div>
                <div className="col-4">
                  <InputWrapper
                    label={translation("email")}
                    name="email"
                    control={control}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Property Details  */}

          {userDetails &&
            userDetails.landlord_property_type !== null &&
            userDetails.landlord_address !== null && (
              <div className="theme-card mb-4">
                <div className="card-top">
                  <div className="card-heading">
                    <h3> {translation("property")}</h3>
                  </div>
                </div>
                <div className="card-mid">
                  <div className="row">
                    <div className="col-3">
                      <InputWrapper
                        name="propertyType"
                        control={control}
                        label={translation("property_type")}
                        disabled
                      />
                    </div>
                    <div className="col-9">
                      <InputWrapper
                        label="Address"
                        name="fullAddress"
                        control={control}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          {/* Review Rating Details  */}
          {userDetails &&
            userDetails.reviewRating_propertyRating !== null &&
            userDetails.reviewRating_rating !== null &&
            userDetails.reviewRating_review_topic !== null && (
              <div className="theme-card mb-4">
                <div className="card-top">
                  <div className="card-heading">
                    <h3> {translation("review")}</h3>
                  </div>
                </div>
                <div className="card-mid">
                  <div className="row">
                    <div className="col-4">
                      <InputWrapper
                        label="Review Type"
                        name="reviewTopic"
                        control={control}
                        disabled
                      />
                    </div>
                    <div className="col-4">
                      <InputWrapper
                        label="Review"
                        name="reviewType"
                        control={control}
                        disabled
                      />
                    </div>
                    <div className="col-4">
                      <InputWrapper
                        label="Rating"
                        name="rating"
                        control={control}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
export default UserDetail;
