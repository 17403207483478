import { useCallback, useEffect, useState } from "react";

import {
  CLAIMED_PROPERTY_LIST,
  CONTENT_LIST,
  LIMIT,
  REVIEW_LIST,
} from "../../../constant/commonConstant";
import {
  DataObject,
  ICommonList,
  IUseInfiniteScrollParams,
  IUseInfiniteScrollReturn,
} from "../../../interfaces/commonInterfaces";

const useInfiniteScroll = ({
  apiService,
  apiParams,
  limit,
  from,
}: IUseInfiniteScrollParams): IUseInfiniteScrollReturn => {
  const [data, setData] = useState<ICommonList[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [prevOffset, setprevOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pendingCount, setPendingCount] = useState<number>(0);

  /**
   * Fetch data
   */
  const fetchData = useCallback(
    async (firstLoad?: boolean, otherParams?: DataObject) => {
      if (offset !== prevOffset || firstLoad) {
        setLoading(true);
        const res = await apiService({
          ...apiParams,
          offset: firstLoad ? 0 : offset,
          limit: limit ?? LIMIT,
          ...otherParams,
        });
        if (res.data && res?.data?.success) {
          if (res?.data?.totalCount) {
            setTotalCount(res.data.totalCount);
          }
          if (from === REVIEW_LIST) {
            setPendingCount(res?.data?.pendingCount);
          }
          if (from === CLAIMED_PROPERTY_LIST) {
            setPendingCount(res?.data?.pendingCount);
          }
          if (from === CONTENT_LIST) {
            setPendingCount(res?.data?.pendingCount);
          }
          if (res.data.currentCount > res.data.totalCount) {
            setHasMore(false);
          }
          setHasMore(true);
          setprevOffset(firstLoad ? 0 : offset);
          setOffset(firstLoad ? 10 : offset + 10);
          setData(firstLoad ? res.data.data : data.concat(res.data.data));
        } else {
          setHasMore(false);
          if (firstLoad) setData([]);
        }

        setLoading(false);
      }
    },
    [offset, prevOffset, apiService, apiParams, limit, from, data]
  );

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  /**
   * Load more jobs on scroll
   */
  const loadMore = () => {
    setTimeout(() => {
      if (hasMore) {
        fetchData(false);
      }
    }, 500);
  };

  return {
    data,
    hasMore,
    loading,
    totalCount,
    setData,
    loadMore,
    fetchData,
    setLoading,
    pendingCount,
  };
};

export default useInfiniteScroll;
