import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getAdvertisementListService } from "../../../service/user";
import { ADVERTISEMENT_LIST, LIMIT } from "../../../constant/commonConstant";

import CommonButton from "../../utilities/commonButton/CommonButton";
import ROUTES from "../../../constant/routes";
import useInfiniteScroll from "../../utilities/useInfiniteScroll/useInfiniteScroll";
import CommonHandleInfiniteScroll from "../../utilities/commonHandleInfiniteScroll/CommonHandleInfiniteScroll";
import "./Effects.scss";

const AdvertisementManagement: React.FC = () => {
  const navigate = useNavigate();
  const { t: translation } = useTranslation();

  const { data, loading, hasMore, loadMore, fetchData,setLoading} = useInfiniteScroll({
    apiService: getAdvertisementListService,
    limit: LIMIT,
    from: ADVERTISEMENT_LIST,
  });

  useEffect(() => {
    fetchData(true);
  }, []);

  return (
    <div className="effects-page">
      <div className="container-fluid">
        <div className="page-inner">
          <div className="theme-card">
            <div className="card-top">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-4">
                  <div className="card-heading">
                    <h3>{translation("advertisement_management")}</h3>
                  </div>
                </div>
                <div className="col-md-12 col-lg-8">
                  <div className="common-head-action">
                    <CommonButton
                      label={translation("add_new_advertisement")}
                      className="primary-btn"
                      onClick={() => navigate(ROUTES.ADD_ADVERTISEMENT)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-mid">
              <CommonHandleInfiniteScroll
                loadMore={loadMore}
                hasMore={hasMore}
                loading={loading}
                data={data}
                from={ADVERTISEMENT_LIST}
                fetchData={fetchData}
                setLoading={setLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdvertisementManagement;
