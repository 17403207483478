import * as yup from "yup";
import { TFunction } from "react-i18next";
import {
  firstName,
  lastName,
  oldPassword,
  password,
} from "./commonFieldValidation";

const updatePasswordValidatinonSchema = (translation: TFunction) =>
  yup.object().shape({
    oldPassword: oldPassword(translation),
    newPassword: password(translation),
    confirmNewPassword: yup
      .string()
      .required(translation("confirm_pass_req"))
      .oneOf(
        [yup.ref("newPassword"), null],
        translation("confirm_password_must_match")
      ),
  });

const resetPasswordValidatinonSchema = (translation: TFunction) =>
  yup.object().shape({
    newPassword: password(translation),
    confirmNewPassword: yup
      .string()
      .required(translation("confirm_pass_req"))
      .oneOf(
        [yup.ref("newPassword"), null],
        translation("confirm_password_must_match")
      ),
  });

const updateProfileValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    firstName: firstName(translation),
    lastName: lastName(translation),
  });

const updatePropertyValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    firstName: firstName(translation),
    lastName: lastName(translation),
  });

export {
  updatePasswordValidatinonSchema,
  updateProfileValidationSchema,
  updatePropertyValidationSchema,
  resetPasswordValidatinonSchema,
};
