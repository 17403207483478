import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../toolkit";
import Header from "../components/utilities/header/Header";
import Sidebar from "../components/utilities/sidebar/Sidebar";
import ChangePassword from "../components/views/changePassword/ChangePassword";
import ContentManagemnet from "../components/views/contentManagement/ContentManagement";
import Requests from "../components/views/reviewManagement/ReviewsManagement";
import ReportedReviews from "../components/views/reportedReviews/ReportedReviews";
import ForgotPassword from "../components/views/userAuth/ForgotPassword";
import Login from "../components/views/userAuth/Login";
import UserDetail from "../components/views/users/UserDetail";
import ClaimedProperty from "../components/views/claimedProperty/ClaimedProperty";
import AddNewContent from "../components/views/contentManagement/AddNewContent";
import Users from "../components/views/users/Users";
import ROUTES from "../constant/routes";
import ProtectedRoutes from "./ProtectedRoutes";
import Verification from "../components/views/userAuth/Verification";
import SetNewPassword from "../components/views/userAuth/SetNewPassword";
import PageNotFound from "../components/views/pageNotFound/PageNotFound";
import AddAboutUsContent from "../components/views/aboutUsManagement/AddAboutUsContent";
import AboutUsContentList from "../components/views/aboutUsManagement/AboutUsContentList";
import AddNewAdvertisement from "../components/views/advertisementManagement/AddNewAdvertisement";
import AdvertisementManagement from "../components/views/advertisementManagement/AdvertisementManagement";

interface Props {
  component: React.ComponentType;
}
export const WithSidebarProtected: React.FC<Props> = ({ component }) => {
  return (
    <div className="main">
      <Sidebar />
      <div className="main-wrapper">
        <Header />
        <div className="main-wrapper-inner">
          <div className="wrapper-pages">
            <ProtectedRoutes component={component} />
          </div>
        </div>
      </div>
    </div>
  );
};
export const NonProtected: React.FC<Props> = ({
  component: RouteComponent,
}) => {
  return <RouteComponent />;
};

const PublicRoutes: React.FC = () => {
  const tokenData = useSelector(
    (state: RootState) => state?.auth?.authData?.token
  );

  return (
    <Routes>
      <Route
        path={ROUTES.REVIEWS}
        element={<WithSidebarProtected component={Requests} />}
      />
      <Route
        path={ROUTES.CLAIMED_PROPERTY}
        element={<WithSidebarProtected component={ClaimedProperty} />}
      />
      <Route
        path={ROUTES.USERS}
        element={<WithSidebarProtected component={Users} />}
      />
      <Route
        path={ROUTES.USER_DETAIL}
        element={<WithSidebarProtected component={UserDetail} />}
      />
      <Route
        path={ROUTES.REPORTED_REVIEW}
        element={<WithSidebarProtected component={ReportedReviews} />}
      />
      <Route
        path={ROUTES.CONTENT}
        element={<WithSidebarProtected component={ContentManagemnet} />}
      />
      <Route
        path={ROUTES.ADVERTISEMENT}
        element={<WithSidebarProtected component={AdvertisementManagement} />}
      />
      <Route
        path={ROUTES.ABOUT_US_CONTENT_LIST}
        element={<WithSidebarProtected component={AboutUsContentList} />}
      />
      <Route
        path={ROUTES.DASHBOARD}
        element={<WithSidebarProtected component={AddNewContent} />}
      />
      <Route
        path={ROUTES.ADD_ADVERTISEMENT}
        element={<WithSidebarProtected component={AddNewAdvertisement} />}
      />
      <Route
        path={ROUTES.ADD_ABOUT_US_CONTENT}
        element={<WithSidebarProtected component={AddAboutUsContent} />}
      />
      <Route
        path={ROUTES.CHANGE_PASSWORD}
        element={<WithSidebarProtected component={ChangePassword} />}
      />
      <Route
        path={ROUTES.PAGE_NOT_FOUND}
        element={<WithSidebarProtected component={PageNotFound} />}
      />

      <Route
        path={ROUTES.HOME}
        element={tokenData ? <Navigate to={ROUTES.DASHBOARD} /> : <Login />}
      />
      <Route
        path={ROUTES.LOGIN}
        element={tokenData ? <Navigate to={ROUTES.DASHBOARD} /> : <Login />}
      />

      <Route
        path={ROUTES.FORGOT_PASSWORD}
        element={
          tokenData ? <Navigate to={ROUTES.DASHBOARD} /> : <ForgotPassword />
        }
      />

      <Route
        path={ROUTES.OTP_VERIFICATION}
        element={
          tokenData ? <Navigate to={ROUTES.DASHBOARD} /> : <Verification />
        }
      />
      <Route
        path={ROUTES.OTP_VERIFICATION}
        element={
          tokenData ? <Navigate to={ROUTES.DASHBOARD} /> : <Verification />
        }
      />
      <Route
        path={ROUTES.RESET_PASSWORD}
        element={
          tokenData ? <Navigate to={ROUTES.DASHBOARD} /> : <SetNewPassword />
        }
      />
    </Routes>
  );
};

export default PublicRoutes;
