import { FC } from "react";
// import { FieldErrors, FieldValues } from 'react-hook-form';

type Props = {
  errors: any;
};

const CommonErrorText: FC<Props> = ({ errors }) => {
  return (
    errors &&
    errors.message && (
      <div className="alert-msg error">
        <p>{errors?.message} </p>
      </div>
    )
  );
};

export default CommonErrorText;
