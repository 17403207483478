import React from "react";

interface IProps {
  svgName: string;
}

const CommonSvgFiles: React.FC<IProps> = ({ svgName }) => {
  switch (svgName) {
    case "cross":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33.182"
          height="33.182"
          viewBox="0 0 33.182 33.182"
        >
          <path
            id="Path_21415"
            data-name="Path 21415"
            d="M19.322,6.529a15.89,15.89,0,0,0,6.4-1.306A17,17,0,0,0,31.02,1.628a16.906,16.906,0,0,0,3.586-5.285,15.961,15.961,0,0,0,1.3-6.412,15.89,15.89,0,0,0-1.306-6.4A17.1,17.1,0,0,0,31-21.776a16.688,16.688,0,0,0-5.294-3.586,16.071,16.071,0,0,0-6.4-1.289,16.01,16.01,0,0,0-6.4,1.289,16.816,16.816,0,0,0-5.294,3.586,16.909,16.909,0,0,0-3.595,5.3,15.98,15.98,0,0,0-1.3,6.4A15.871,15.871,0,0,0,4.03-3.657,17.027,17.027,0,0,0,7.624,1.628,17.027,17.027,0,0,0,12.91,5.223,15.871,15.871,0,0,0,19.322,6.529Zm0-3.04a13.293,13.293,0,0,1-5.277-1.05A13.608,13.608,0,0,1,9.725-.472a13.49,13.49,0,0,1-2.9-4.32A13.387,13.387,0,0,1,5.78-10.07a13.387,13.387,0,0,1,1.042-5.277,13.49,13.49,0,0,1,2.9-4.32,13.516,13.516,0,0,1,4.312-2.9A13.325,13.325,0,0,1,19.3-23.612,13.449,13.449,0,0,1,24.59-22.57a13.465,13.465,0,0,1,4.329,2.9,13.49,13.49,0,0,1,2.9,4.32,13.387,13.387,0,0,1,1.042,5.277,13.387,13.387,0,0,1-1.042,5.277,13.49,13.49,0,0,1-2.9,4.32A13.608,13.608,0,0,1,24.6,2.439,13.293,13.293,0,0,1,19.322,3.49ZM13.909-3.256a1.255,1.255,0,0,0,.99-.427L19.3-8.089l4.44,4.406a1.323,1.323,0,0,0,.99.427,1.33,1.33,0,0,0,.973-.41,1.33,1.33,0,0,0,.41-.973,1.359,1.359,0,0,0-.427-.99l-4.423-4.423,4.44-4.457a1.233,1.233,0,0,0,.427-.939,1.314,1.314,0,0,0-.41-.99,1.359,1.359,0,0,0-.973-.393,1.367,1.367,0,0,0-.973.41L19.3-11.982l-4.44-4.423a1.24,1.24,0,0,0-.956-.41,1.35,1.35,0,0,0-.982.393,1.307,1.307,0,0,0-.4.973,1.251,1.251,0,0,0,.427.956l4.423,4.44-4.423,4.44a1.269,1.269,0,0,0-.427.973,1.344,1.344,0,0,0,.4.973A1.321,1.321,0,0,0,13.909-3.256Z"
            transform="translate(-2.723 26.652)"
          />
        </svg>
      );

    case "delete":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="delete_2_"
          data-name="delete (2)"
          width="25.354"
          height="32.026"
          viewBox="0 0 25.354 32.026"
        >
          <path
            id="Path_21608"
            data-name="Path 21608"
            d="M223.147,154.7a.749.749,0,0,0-.749.749v14.16a.749.749,0,0,0,1.5,0v-14.16A.749.749,0,0,0,223.147,154.7Zm0,0"
            transform="translate(-206.163 -143.077)"
          />
          <path
            id="Path_21609"
            data-name="Path 21609"
            d="M105.147,154.7a.749.749,0,0,0-.749.749v14.16a.749.749,0,0,0,1.5,0v-14.16A.749.749,0,0,0,105.147,154.7Zm0,0"
            transform="translate(-96.777 -143.077)"
          />
          <path
            id="Path_21610"
            data-name="Path 21610"
            d="M2.073,9.533V28.012a4.2,4.2,0,0,0,1.072,2.854,3.558,3.558,0,0,0,2.612,1.159H19.59A3.557,3.557,0,0,0,22.2,30.866a4.2,4.2,0,0,0,1.072-2.854V9.533a2.864,2.864,0,0,0,2.053-3.14A2.818,2.818,0,0,0,22.558,3.9H18.815V2.961a2.986,2.986,0,0,0-.844-2.1A2.835,2.835,0,0,0,15.92,0H9.428a2.835,2.835,0,0,0-2.052.86,2.986,2.986,0,0,0-.844,2.1V3.9H2.79A2.818,2.818,0,0,0,.02,6.393a2.864,2.864,0,0,0,2.053,3.14ZM19.59,30.525H5.758a2.352,2.352,0,0,1-2.222-2.513V9.6H21.812V28.012a2.352,2.352,0,0,1-2.222,2.513ZM7.995,2.961A1.463,1.463,0,0,1,8.41,1.919,1.39,1.39,0,0,1,9.428,1.5H15.92a1.39,1.39,0,0,1,1.018.42,1.463,1.463,0,0,1,.415,1.042V3.9H7.995ZM2.79,5.4H22.558a1.351,1.351,0,0,1,0,2.7H2.79a1.351,1.351,0,0,1,0-2.7Zm0,0"
            transform="translate(0.003 0.002)"
          />
          <path
            id="Path_21611"
            data-name="Path 21611"
            d="M164.147,154.7a.749.749,0,0,0-.749.749v14.16a.749.749,0,0,0,1.5,0v-14.16A.749.749,0,0,0,164.147,154.7Zm0,0"
            transform="translate(-151.47 -143.077)"
          />
        </svg>
      );
    case "edit":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33.182"
          height="33.182"
          viewBox="0 0 33.182 33.182"
        >
          <g
            id="Icon_feather-edit"
            data-name="Icon feather-edit"
            transform="translate(-1.5 -1.318)"
          >
            <path
              id="Path_21409"
              data-name="Path 21409"
              d="M27,34.5H6A4.505,4.505,0,0,1,1.5,30V9A4.505,4.505,0,0,1,6,4.5H16.5a1.5,1.5,0,0,1,0,3H6A1.5,1.5,0,0,0,4.5,9V30A1.5,1.5,0,0,0,6,31.5H27A1.5,1.5,0,0,0,28.5,30V19.5a1.5,1.5,0,0,1,3,0V30A4.505,4.505,0,0,1,27,34.5Z"
              fill="#000"
            />
            <path
              id="Path_21410"
              data-name="Path 21410"
              d="M30,1.318a4.682,4.682,0,0,1,3.311,7.993l-14.25,14.25a1.5,1.5,0,0,1-.7.395l-6,1.5a1.5,1.5,0,0,1-1.819-1.819l1.5-6a1.5,1.5,0,0,1,.395-.7l14.25-14.25A4.651,4.651,0,0,1,30,1.318ZM17.233,21.146,31.189,7.189a1.682,1.682,0,0,0-2.379-2.379L14.854,18.767l-.793,3.172Z"
              fill="#000"
            />
          </g>
        </svg>
      );
    case "uploadFile":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="57.39"
          height="41.759"
          viewBox="0 0 57.39 41.759"
        >
          <path
            id="Path_21650"
            data-name="Path 21650"
            d="M44.843,108.409H32.53V95.967H36.6a1.273,1.273,0,0,0,1.032-2.017L29.715,83a1.261,1.261,0,0,0-2.052,0L19.748,93.95a1.27,1.27,0,0,0,1.032,2.017h4.069v12.442H11.058C4.9,108.069,0,102.311,0,96.072A11.682,11.682,0,0,1,5.793,85.987a7.832,7.832,0,0,1-.481-2.732A7.942,7.942,0,0,1,13.263,75.3a7.815,7.815,0,0,1,2.721.481,15.917,15.917,0,0,1,30.243,5.254,13.847,13.847,0,0,1-1.384,27.37Z"
            transform="translate(0 -66.65)"
            fill="#0050b3"
          />
        </svg>
      );
    case "arrowDown":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="11.25"
          viewBox="0 0 20 11.25"
        >
          <path
            id="Arrow_-_Down_2"
            data-name="Arrow - Down 2"
            d="M4.3,7.807a1.014,1.014,0,0,1,1.34-.1l.115.1L14,16.217l8.244-8.409a1.014,1.014,0,0,1,1.34-.1l.115.1a1.065,1.065,0,0,1,.1,1.366l-.1.118-8.971,9.151a1.014,1.014,0,0,1-1.34.1l-.115-.1L4.3,9.292A1.064,1.064,0,0,1,4.3,7.807Z"
            transform="translate(-4 -7.5)"
          />
        </svg>
      );
    case "logout":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Logout"
          width="20"
          height="19.003"
          viewBox="0 0 20 19.003"
        >
          <path
            id="Path_432"
            data-name="Path 432"
            d="M21.806,12.723H9.751a.6.6,0,0,1,0-1.2H21.806a.6.6,0,0,1,0,1.2Z"
            transform="translate(-2.408 -2.504)"
          />
          <path
            id="Path_433"
            data-name="Path 433"
            d="M18.865,15.645a.6.6,0,0,1-.424-1.026l2.5-2.494-2.5-2.494a.6.6,0,1,1,.848-.851L22.22,11.7a.6.6,0,0,1,0,.851L19.289,15.47A.6.6,0,0,1,18.865,15.645Z"
            transform="translate(-2.397 -2.509)"
          />
          <path
            id="Path_434"
            data-name="Path 434"
            d="M8.988,21.153c-3.587,0-5.441-.645-6.4-2.227-.929-1.533-.929-3.817-.929-7.275s0-5.742.929-7.275C3.547,2.8,5.4,2.15,8.988,2.15c2.73,0,4.361.351,5.455,1.172a5.363,5.363,0,0,1,1.775,4.061.571.571,0,1,1-1.137.106C14.8,4.4,13.837,3.308,8.988,3.308c-3.133,0-4.705.485-5.426,1.675C2.8,6.237,2.8,8.391,2.8,11.652s0,5.414.76,6.669C4.282,19.51,5.854,20,8.988,20c4.85,0,5.812-1.1,6.093-4.182a.571.571,0,1,1,1.137.106,5.363,5.363,0,0,1-1.775,4.061C13.348,20.8,11.717,21.153,8.988,21.153Z"
            transform="translate(-1.659 -2.15)"
          />
        </svg>
      );
    case "rightArrow":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="512"
          viewBox="0 0 512 512"
          width="512"
        >
          <g id="ARROW_48" data-name="ARROW 48">
            <path d="m24 232h406.09l-126.34-126.35a24 24 0 0 1 0-33.94 24 24 0 0 1 33.94 0l166.89 166.85a24.66 24.66 0 0 1 0 34.89l-166.89 166.84a24 24 0 0 1 -33.94 0 24 24 0 0 1 0-33.94l126.34-126.35h-406.09a24 24 0 0 1 -24-24 24 24 0 0 1 24-24z" />
          </g>
        </svg>
      );
    case "home":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28.484"
          height="25"
          viewBox="0 0 28.484 25"
        >
          <path
            id="Path_5"
            data-name="Path 5"
            d="M14.374-2.237h6.8v-8.552a.89.89,0,0,0-.245-.659.892.892,0,0,0-.66-.245H15.291a.913.913,0,0,0-.666.245.878.878,0,0,0-.251.659ZM9.76-.856H25.715a2.769,2.769,0,0,0,2.027-.711,2.664,2.664,0,0,0,.724-1.992V-16.6l-2.116-1.439V-4.153a1.188,1.188,0,0,1-.3.868,1.137,1.137,0,0,1-.85.3H10.271a1.137,1.137,0,0,1-.85-.3,1.188,1.188,0,0,1-.3-.868V-18.042L7.009-16.6V-3.559a2.664,2.664,0,0,0,.724,1.992A2.769,2.769,0,0,0,9.76-.856ZM3.53-14.082a1.026,1.026,0,0,0,.3.729,1.082,1.082,0,0,0,.822.317,1,1,0,0,0,.489-.124,2.487,2.487,0,0,0,.419-.288l11.765-9.866a.661.661,0,0,1,.457-.194.705.705,0,0,1,.469.194l11.741,9.866a2.936,2.936,0,0,0,.43.288,1,1,0,0,0,.49.124,1.166,1.166,0,0,0,.781-.271.92.92,0,0,0,.32-.741,1.126,1.126,0,0,0-.408-.9L19.33-25.25a2.3,2.3,0,0,0-1.533-.605,2.389,2.389,0,0,0-1.557.605L3.961-14.942A1.07,1.07,0,0,0,3.53-14.082ZM25.3-19.613l3.165,2.667v-5.578a.844.844,0,0,0-.234-.625.845.845,0,0,0-.625-.234H26.174a.867.867,0,0,0-.632.234.832.832,0,0,0-.24.625Z"
            transform="translate(-3.53 25.856)"
          />
        </svg>
      );
    case "upDownArrow":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Capa_1"
          x="0px"
          y="0px"
          width="451.847px"
          height="451.847px"
          viewBox="0 0 451.847 451.847"
          xmlSpace="preserve"
        >
          <g>
            <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" />
          </g>
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
        </svg>
      );
    default:
      break;
  }
  return null;
};

export default CommonSvgFiles;
