import config from "../config/config";
import { ADVERTISEMENT_LIST, CONTENT_LIST, CUSTOM_REVIEW_STATUS, CUSTOM_REVIEW_TEXT, REPORTED_REVIEW_LIST, USER_LIST } from "../constant/commonConstant";
import { getSignedUrl } from "../service/user";

export const pushFileToS3 = async (signedUrl: string, file: Blob) => {
  const myHeaders = new Headers({
    "Content-Type": file.type,
    "x-amz-acl": "public-read",
  });
  return fetch(signedUrl, {
    method: "PUT",
    headers: myHeaders,
    body: file,
  });
};

/**
 * Generating File URL
 * @param file
 * @param filePath
 * @returns
 */
export const uploadFileOnS3 = async (file: Blob, filePath: string) => {
  const body = {
    filePath,
    fileFormat: file.type as string,
  };
  let signedUrl;
  const presignedUrl = await getSignedUrl(body);

  if (presignedUrl && presignedUrl.data) {
    const response = await pushFileToS3(presignedUrl.data.data, file);
    if (response?.url) {
      signedUrl = response?.url.split("?Content")?.[0];
    }
  }
  return signedUrl;
};

export const filePath = async (authId: number, name?: string) => {
  return `${config.bucket}/${new Date().getTime()}-${authId}-${name}`;
};

export const checkFileDimension = (fileObj: File): boolean => {
  if (fileObj) {
    const img = new Image();
    img.src = URL.createObjectURL(fileObj);
    img.onload = () => {
      if (img.naturalWidth > 280 && img.naturalHeight > 350) {
        return true;
      }
      return false;
    };
  }
  return false;
};

export const commonFileError = (fileObj: File): string | undefined => {
  if (fileObj) {
    const fileType = fileObj.type.split("/")[0];
    if (fileType !== "image") {
      return "you_can_upload_only_images";
    }
    if (fileType === "image") {
      const img = new Image();
      img.src = URL.createObjectURL(fileObj);
      img.onload = async () => {
        if (img.naturalWidth > 280 && img.naturalHeight > 350) {
          return "you_can_upload_only_280x350_size_file";
        }
        return "no_err";
      };
    }
    return "no_err";
  }
};

export const headerMsgHandlerForCommonPopup = (args: string): string => {
  switch (args) {
    case USER_LIST:
      return "User Details";
    case REPORTED_REVIEW_LIST:
      return "Reported Review Details";
    case ADVERTISEMENT_LIST:
      return "Images";
    default:
      return "";
  }
};

export const fromHandlerForCommonPopup = (arg: string): string => {
  switch (arg) {
    case USER_LIST:
      return USER_LIST;
    case REPORTED_REVIEW_LIST:
      return REPORTED_REVIEW_LIST;
    case ADVERTISEMENT_LIST:
      return ADVERTISEMENT_LIST;
    case CONTENT_LIST:
      return CONTENT_LIST;
    default:
      return "";
  }
};

export const handleToggle = (arg: string) => {
  switch (arg) {
    // Pending
    case CUSTOM_REVIEW_STATUS.HAVE_REVIEW:
      return false;
    // Approve
    case CUSTOM_REVIEW_STATUS.APPROVED_REVIEW:
      return true;
    // Reject
    case CUSTOM_REVIEW_STATUS.REJECTED_REVIEW:
      return false;
    default:
      false;
  }
};

export const customRviewText = (arg: string): string => {
  switch (arg) {
    case CUSTOM_REVIEW_STATUS.HAVE_REVIEW:
      return CUSTOM_REVIEW_TEXT.PEDNING;
    case CUSTOM_REVIEW_STATUS.APPROVED_REVIEW:
      return CUSTOM_REVIEW_TEXT.APPROVED;
    case CUSTOM_REVIEW_STATUS.REJECTED_REVIEW:
      return CUSTOM_REVIEW_TEXT.REJECTED;
    default:
      return "";
  }
};
