import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../toolkit";

import userPlaceholder from "../../../assets/images/user-placeholder.jpg";
import CommonSvgFiles from "../commonSvgFiles/CommonSvgFiles";
import ROUTES from "../../../constant/routes";
import "./Header.scss";
import { logout } from "../../../toolkit/slices/auth";

const Header: React.FC = () => {
  const { t: translation } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = useSelector((state: RootState) => state.auth.authData);
  const pathName: string = window.location.pathname.split("/")[1];
  const handleLogout = async () => {
    dispatch(logout());
    navigate(ROUTES.LOGIN);
  };
  return (
    <div className="header">
      <div className="container-fluid">
        <div className="section-inner">
          <div className="row">
            <div className="col-md-7">
              <div className="header-breadcrumb">
                <ul>
                  <li>
                    <Link to={ROUTES.HOME}>
                      <CommonSvgFiles svgName="home" />
                    </Link>
                  </li>
                  <li>
                    <span>
                      <CommonSvgFiles svgName="rightArrow" />
                    </span>
                  </li>
                  <li className="active">
                    <Link to={ROUTES.DASHBOARD}>
                      {/* {translation("dashboard")} */}
                      {translation(pathName)}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-5">
              <div className="header-right">
                <div className="dropdown user-drop">
                  <button
                    type="button"
                    className="user-drop-btn dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <div className="circle-img">
                      <img src={userPlaceholder} alt="userPlaceholder" />
                    </div>
                    {authData && authData?.firstName}
                    <CommonSvgFiles svgName="arrowDown" />
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={handleLogout}
                      >
                        {translation("logout")}
                        <CommonSvgFiles svgName="logout" />
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
