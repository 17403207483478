import * as http from "../utils/http";
import endpoint from "../constant/endpoints";
import ApiResponse from "../resources/entity/IApiResponse";
import {
  // ISignUp,
  ILoginData,
  IForgetPassword,
  IVerifyOtpParams,
} from "../interfaces/authInterface";
import { IUpdatePasssword } from "../interfaces/commonInterfaces";

export const loginService = (data: ILoginData): Promise<ApiResponse> => {
  return http.post(endpoint.auth.LOGIN, data);
};

export const forgotPasswordService = (
  data: IForgetPassword
): Promise<ApiResponse> => {
  return http.post(endpoint.auth.FORGOT_PASSWORD, data);
};

export const verifyOtpService = (
  data: IVerifyOtpParams
): Promise<ApiResponse> => {
  return http.post(endpoint.auth.VERIFY_OTP, data);
};

export const changePasswordService = (
  data: IUpdatePasssword
): Promise<ApiResponse> => http.patch(`${endpoint.auth.CHANGE_PASSWORD}`, data);

export const resetPasswordService = (
  data: IUpdatePasssword
): Promise<ApiResponse> => http.patch(`${endpoint.auth.RESET_PASSWORD}`, data);
